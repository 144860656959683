import React, { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import FieldSelect, {
  buildCountryOption,
  CountrySelect,
} from "../../components/FieldSelect";
import { getLocaleValue } from "@happy-rabbit/gatsby-sanity-cms";

const labels = {
  global: "Global",
  eu: "Europe",
  en: "English",
  se: "Sweden",
  sv: "Svenska",
};

const RegionCountryLang = props => {
  const {
    listOfCountries,
    listOfRegions,
    onCountryChange,
    onLanguageChange,
    onRegionChange,
    selectedCountry,
    selectedLanguage,
    selectedRegion,
    sitesById,
  } = props;

  const formRegion = selectedRegion?._id || null;
  const formCountry = selectedCountry?.slug?.current || null;
  const formLanguage = selectedLanguage || null;

  // Build and cache a list of region options
  const regionOptions = useMemo(
    () =>
      listOfRegions.map(region => ({
        label: getLocaleValue(region.name, selectedLanguage),
        value: region._id,
      })),
    [listOfRegions, selectedLanguage]
  );

  // Build and cache a list of country options
  const countryOptions = useMemo(
    () =>
      listOfCountries
        .filter(c => !selectedRegion || c.region?._id === selectedRegion._id)
        .map(c => buildCountryOption(c, selectedLanguage)),
    [listOfCountries, selectedRegion, selectedLanguage]
  );

  // Build and cache a list of language options for the selected country
  const langOptions = useMemo(() => {
    const enabledLanguages = sitesById[selectedCountry?.site._id]
      ?.languages || { en: true };

    // If country is Åland or Finland, add Swedish as an option
    if (
      selectedCountry?.slug?.current === "ax" ||
      selectedCountry?.slug?.current === "fi"
    ) {
      enabledLanguages["sv"] = true;
    }

    return Object.keys(enabledLanguages)
      .filter(l => enabledLanguages[l])
      .map(l => ({ label: labels[l], value: l }));
  }, [selectedCountry, sitesById]);

  return (
    <>
      <h2 className="text-xl pb-4 text-center">
        <FormattedMessage
          id="siteLangSelector.please-select"
          description="Prompt to select country and language"
          defaultMessage={`Please select your country, language and VAT settings`}
        />
      </h2>

      <FieldSelect
        attribute={"region"}
        defaultValue={formRegion}
        label={
          <FormattedMessage
            id="siteLangSelector.region"
            description="Region Label"
            defaultMessage={`Region`}
          />
        }
        onChange={onRegionChange}
        options={regionOptions}
      />

      <CountrySelect
        attribute={"country"}
        defaultValue={formCountry}
        label={
          <FormattedMessage
            id="siteLangSelector.country"
            description="Country Label"
            defaultMessage={`Country`}
          />
        }
        onChange={onCountryChange}
        options={countryOptions}
      />

      <FieldSelect
        attribute={"language"}
        defaultValue={formLanguage}
        label={
          <FormattedMessage
            id="siteLangSelector.language"
            description="Language Label"
            defaultMessage={`Language`}
          />
        }
        onChange={onLanguageChange}
        options={langOptions}
      />
    </>
  );
};

export default RegionCountryLang;
