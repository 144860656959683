import { useMemo } from "react";

export const useSiteLang = args => {
  const { countryEdges, siteEdges } = args;

  // Cache regions
  const [regionById, listOfRegions] = useMemo(() => {
    const regions = countryEdges.reduce(
      (acc, c) =>
        c.node.region ? { ...acc, [c.node.region._id]: c.node.region } : acc,
      {}
    );
    return [
      regions,
      Object.values(regions).sort((a, b) =>
        ("" + a.name?.en).localeCompare(b.name?.en)
      ),
    ];
  }, [countryEdges]);

  const listOfCountries = useMemo(
    () =>
      countryEdges
        .map(c => c.node)
        .sort((a, b) => ("" + a.sortBy).localeCompare(b.sortBy)),
    [countryEdges]
  );
  const listOfSites = useMemo(() => siteEdges.map(c => c.node), [siteEdges]);

  // Cache all Sites by id
  const sitesById = useMemo(
    () => listOfSites.reduce((acc, s) => ({ ...acc, [s._id]: s }), {}),
    [listOfSites]
  );

  // Cache all countries by slug
  const countryBySlug = useMemo(
    () =>
      listOfCountries.reduce((acc, c) => ({ ...acc, [c.slug.current]: c }), {}),
    [listOfCountries]
  );
  return {
    countryBySlug,
    regionById,
    sitesById,

    listOfCountries,
    listOfRegions,
    listOfSites,
  };
};
