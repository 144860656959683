import React, { useMemo } from "react";

import ProductGrid from "../ProductGrid";
import ModifiedProductGrid from '../ModifiedProductGrid'
import {
  withBestsellers,
  withDiscontinuedProducts,
  withNewProducts,
  withProfilingProducts,
} from "../../queries";

const BaseIndex = props => {
  const { productFilter } = props;
  const { useModifiedGrid } = props;
  const { allSanityProductPageVariant } = props;
  const productPageVariants = useMemo(
    () => allSanityProductPageVariant.edges.map(edge => edge.node),
    [allSanityProductPageVariant]
  );
  return useModifiedGrid || ['bestsellers','new-products','profiling-friendly'].includes(productFilter)
  ? <ModifiedProductGrid productPageVariants={productPageVariants} />
  : <ProductGrid productPageVariants={productPageVariants} />
};

const BestSellerIndex = withBestsellers(BaseIndex);
const NewsIndex = withNewProducts(BaseIndex);
const ProfilingIndex = withProfilingProducts(BaseIndex);
const DiscontinuedIndex = withDiscontinuedProducts(BaseIndex);
const FromListIndex = props => {
  const { productList } = props;
  const allSanityProductPageVariant = useMemo(() => ({
    edges: (productList || []).map(product => ({ node: product })),
  }), [productList]);
  return <BaseIndex
    allSanityProductPageVariant={allSanityProductPageVariant}
    useModifiedGrid={props.pageConfig?.slug.current === '/shop/accessories'}
  />
};

const ProductIndex = props => {
  const { pageSection } = props;
  const { productFilter } = pageSection;
  const IndexComp = {
    "bestsellers": BestSellerIndex,
    "new-products": NewsIndex,
    "profiling-friendly": ProfilingIndex,
    "from-list": FromListIndex,
    "discontinued": DiscontinuedIndex,
  }[productFilter];

  if (!IndexComp) return null;

  return <IndexComp {...pageSection} {...props.page} />

  // return (
  //   <div
  //     className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4"
  //     role="grid"
  //   >
  //     <IndexComp {...pageSection} />
  //   </div>
  // );
};

export default ProductIndex;
