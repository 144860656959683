import React from "react";
import { FaTimes } from "react-icons/fa";
import clsx from "clsx";
import DrawerProvider, { useDrawerStateContext } from "../../contexts/DrawerContext";

import ShoppingCart from "./ShoppingCart";

import './index.css';

const DrawerContainer = props => {
  const { children } = props;

  const { close, drawerOpen } = useDrawerStateContext();

  return (
    <div
      className={clsx("drawer-wrapper bg-white transition duration-100 ease-in-out", {"drawer-open": drawerOpen === 'right'})}
      onClick={() => drawerOpen && close()}
    >
      <div className="drawer-content">
        {children}
      </div>
    </div>
  );
};

const RightDrawer = props => {
  const { close, drawerOpen } = useDrawerStateContext();

  return (
    <aside className="side-drawer fixed right-0 top-0 bottom-0 pt-16">
      <div className="fixed top-0 right-0 text-right p-2">
        <FaTimes
          className="cursor-pointer"
          onClick={() => close()}
        />
      </div>
      <div className="h-full">
        {drawerOpen === 'right' ? <ShoppingCart /> : null}
      </div>
    </aside>
  );
};

const Drawer = ({ children }) => (
  <DrawerProvider>
    <DrawerContainer>
      {children}
    </DrawerContainer>
    <RightDrawer />
  </DrawerProvider>
)

export default Drawer