import React, { useState, useMemo, useEffect } from "react";
import { FaInstagram, FaFacebook, FaYoutube, FaLinkedin } from "react-icons/fa";
import { FormattedMessage } from "react-intl";
import { Cookies } from "react-cookie-consent";

import { withNavs } from "../../queries";
import SiteLangSelector from "../SiteLangSelector";

import Column from "./Column";
import CountryButton from "./CountryButton";
import { useCountryStateContext } from "../../contexts/CountryContext";
import PaymentOptions from "./PaymentOptions";
import { navigate } from "gatsby";
import { useCMSContext } from "@happy-rabbit/gatsby-sanity-cms";
import NewsletterForm from "../../components/NewsletterForm";


const Footer = (props) => {
  const {
    allSanityNav: { edges: navEdges = [] },
  } = props;

  const [selectLang, setSelectLang] = useState(false);
  const [accepted, setAccepted] = useState(null)

  const navsById = useMemo(() => navEdges.reduce((acc, { node: nav }) => ({ ...acc, [nav.navId]: nav }), {}), [navEdges]);
  const companyMenu = navsById['footer-company'];
  const supportMenu = navsById['footer-support'];
  const termsPrivacyMenu = navsById['terms-and-policy'];
  
  const { lang, pageConfig, site } = useCMSContext();
  const { country, lastSelectedSitePrefix } = useCountryStateContext();

  // Handles the initial verification regarding Cookie policy accepted and always open
  // SiteLangSelector if the cookie has not been set.
  useEffect(() => {
    if (!accepted) {
      const hasAccepted = Cookies.get('gatsby-gdpr-google-tagmanager') === 'true';
      setAccepted(hasAccepted);
      if (!hasAccepted && !selectLang) {
        setSelectLang(true);
      }
    }
  }, [selectLang, accepted]);

  // If the cookie policy has previously been accepted but no country is currently
  // selected (can happen for previous visitors returning to a site they did not
  // previously select), we either automatically forward them to their previous
  // choice, or prompt them to select the country of their choice.
  useEffect(() => {
    if (accepted && !selectLang && !country) {
      if (site._id === 'global' && lastSelectedSitePrefix && pageConfig?.slug?.current !== '/404') {
        // User has made a decission before, but not for this site. Redirect to the last site selected.
        navigate(`${lastSelectedSitePrefix}${pageConfig?.slug?.current}`);
      } else {
        setSelectLang(true);
      }
    }
  }, [accepted, selectLang, country, lastSelectedSitePrefix, site]);

  return (
    <footer className="bg-depalmaBlue text-depalmaWhite px-6">
      <div className="xl:container mx-auto pt-8 grid grid-cols-2 md:grid-cols-4 gap-4 mb-16">
        <div className="col-span-2 md:col-span-1 items-center md:items-start flex flex-col">
          <div className="flex-0 h-12">
            <CountryButton onClick={() => setSelectLang(true)} />
          </div>

          <div className="mb-4 flex gap-4 text-3xl justify-center items-center md:justify-start md:flex-wrap h-6">
            {country ? <PaymentOptions paymentMethods={country.paymentMethods} /> : null}
          </div>
        </div>

        <Column
          nav={companyMenu}
          lang={lang}
          site={site}
        />

        <Column
          nav={supportMenu}
          lang={lang}
          site={site}
        />

        <div className="col-span-2 md:col-span-1 items-center md:items-start flex flex-col">
          <h2 className="mb-4 averta-bold">
            <FormattedMessage
              id="footer.follow-us"
              description="Follow Us header"
              defaultMessage="Follow Us"
            />
          </h2>

          <div className="mb-8 flex text-3xl">
            <a href="https://www.instagram.com/depalmaworkwear/?hl=en" target="_blank" rel="noopener" className="mr-4" aria-label="Instagram"><FaInstagram /></a>
            <a href="https://www.facebook.com/depalmaworkwear/" target="_blank" rel="noopener" className="mr-4" aria-label="Facebook"><FaFacebook /></a>
            <a href="https://www.youtube.com/channel/UCvH-AZZ2-LkRmJAOeuSH45A" target="_blank" rel="noopener" className="mr-4" aria-label="YouTube"><FaYoutube /></a>
            <a href="https://www.linkedin.com/company/depalma-workwear/" target="_blank" rel="noopener" aria-label="LinkedIn"><FaLinkedin /></a>
          </div>

          <div className="text-center md:text-left">
            <NewsletterForm
              formId={'190711949'}
              source={"footer"}
            />
          </div>
        </div>
      </div>

      <div className="flex-0 mb-12">
        <div className="text-sm flex justify-center flex-col sm:flex-row items-center sm:items-stretch">
          <div>© {new Date().getFullYear()} DePalma Workwear Limited</div>
        </div>
      </div>

      {selectLang ? (
        <SiteLangSelector onClose={() => setSelectLang(false)} />
      ) : null}
    </footer>
  );
};

export default withNavs(Footer);
