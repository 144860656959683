import React from "react";
import { FaFlag, FaAngleDown } from "react-icons/fa";
import { FormattedMessage } from "react-intl";

import { useCountryStateContext } from "../../contexts/CountryContext";
import { LocaleString } from "@happy-rabbit/gatsby-sanity-cms";

import "flag-icon-css/css/flag-icons.min.css";

const CountryButton = props => {
  const { onClick } = props;

  const { country } = useCountryStateContext();

  return (
    <button className="flex items-center content-start" onClick={onClick}>
      <span className="flex-0">
        {country ? (
          <span className={`mr-1 flag-icon flag-icon-${country.slug.current}`}/>
        ) : <FaFlag />}
      </span>

      <span className="flex-auto mx-1">
        {country ? <LocaleString value={country.name} /> : (
          <FormattedMessage
            id="footer.select-country"
            description="Country selector"
            defaultMessage="Select Country"
          />
        )}
      </span>

      <span className="flex-0 w-4">
        <FaAngleDown />
      </span>
    </button>
  );
};

export default CountryButton;
