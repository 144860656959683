import React, { useCallback, useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { Configure, InstantSearch, SearchBox, Hits, connectSearchBox, Pagination } from 'react-instantsearch-dom';

import { searchClient } from "../../utils/algolia";

import SearchBlack from "../../images/search_black.svg";
import SearchWhite from "../../images/search_white.svg";
import { useProductScope } from "../../utils/productScope";
import { PageLink, ResponsiveImage } from "@happy-rabbit/gatsby-sanity-cms/components";
import { LocaleString } from "@happy-rabbit/gatsby-sanity-cms";
import { useCountryStateContext } from "../../contexts/CountryContext";
import Price from "../../components/Price";
import { FaSpinner } from "react-icons/fa";
import eventStacker from "../../utils/eventStacker";
const VirtualSearchBox = connectSearchBox(() => <span />);


const ProductPageHit = props => {
  const { hit } = props;
  const { pageConfig, product, productColorway } = hit;

  const {
    scopedProductPhotos,
    selectedVariant,
    possibleProductVariants,
  } = useProductScope(product, productColorway);

  const productPhoto = scopedProductPhotos[0];

  const { currencyCode } = useCountryStateContext();
  const price = ((selectedVariant || possibleProductVariants[0])?.price || {})[currencyCode?.toLowerCase()];

  return (
    <PageLink pageConfig={pageConfig}>
      <div className="flex items-center hover:bg-depalmaBlue hover:text-depalmaWhite">
        <div className="h-20 w-20 p-1 mr-1 flex-0 flex-shrink-0">
          {productPhoto? (
            <ResponsiveImage
              alt={''}
              boxRatio={{xs: '1:1'}}
              image={productPhoto.image}
              noOfColsArr={[4]}
            />
          ) : null}
        </div>
        <div className="text-left text-sm">
          <h3>
            <span className="averta-bold">
              {product?.name}
            </span>
            {' '}
            <LocaleString value={product?.description} />
            {' - '}
            <LocaleString value={productColorway.name} />
          </h3>
          {price ? <h4><Price {...price} recalculateForVAT={true} /></h4> : null}
        </div>
      </div>
    </PageLink>
  );
};

const SearchResults = props => {
  const { onClose, query, setQuery, searchValue, setSearchValue } = props;

  const [searching, setSearching] = useState(false);
  const [waiting, setWaiting] = useState(false);

  // Ref for the Modal frame (does not incl. backdrop)
  const ref = useRef();

  useEffect(() => {
    const eventStackId = eventStacker.addListenable({
      // Closes on click outside of the Modal frame
      click: (e) => ref.current && !ref.current.contains(e.target) && onClose(),

      // Closes on Esc key being pressed
      keydown: (e) => e.keyCode === 27 && onClose(),
    });

    return () => eventStacker.removeListenable(eventStackId);
  }, [onClose, ref]);

  useEffect(() => {
    if (searchValue) {
      setWaiting(true);
      const handle = setTimeout(() => {
        setQuery(searchValue);
        setWaiting(false);
        setSearching(true);

        // Workaround for InstantSearch not seem to have any callbacks for when search has finished
        setTimeout(() => setSearching(false), 1000);
      }, 500);

      return () => clearTimeout(handle);
    } else {
      setQuery(null);
      setWaiting(false);
    }
  }, [searchValue])

  const handleSearchChange = e => {
    setSearchValue(e.target.value);
  };

  return (
    <div className="fixed lg:absolute right-0 pr-1 md:pr-0 md:right-auto lg:right-0 left-0 pl-1 md:pl-20 lg:pl-0 lg:left-auto text-depalmaBlack">
      <div className="shadow-lg bg-white w-full md:w-80" ref={ref}>
        <div className="flex border border-gray-500">
          <div className="w-10 flex justify-center items-center">
            {searching ? <FaSpinner className="spinner" /> : <img src={SearchBlack} alt={'Search'} className="h-6" />}
          </div>
          <input
            className={clsx("flex-1 block appearance-none w-full py-2 pr-3 text-gray-700 leading-tight focus:outline-none", { 'border-red-500': false })}
            onChange={handleSearchChange}
            placeholder="Search"
            type="text"
            value={searchValue}
          />
        </div>

        {query ? (
          <div className="">
            <InstantSearch
              indexName="productPageVariants"
              searchClient={searchClient}
              searchState={{query}}
            >
              <VirtualSearchBox />
              <Configure hitsPerPage={4} />
              <Hits hitComponent={ProductPageHit} />
            </InstantSearch>
          </div>
        ) : null}
      </div>
    </div>
  );
}

const SearchButton = props => {
  const { headerTheme, menuIsOpen } = props;
  const [searchOpen, setSearchOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [query, setQuery] = useState(null);

  const handleClose = useCallback(() => {
    setSearchOpen(false);
  }, []);

  return (
    <div className="relative">
      <button className="focus:outline-none" onClick={() => !searchOpen && setSearchOpen(true)}>
        <img src={SearchBlack} alt={'Search'} className={clsx("h-6", headerTheme === 'white' && !menuIsOpen ? "show-on-sticky hidden" : '')} />
        <img src={SearchWhite} alt={'Search'} className={clsx("h-6", headerTheme === 'white' && !menuIsOpen ? "hide-on-sticky" : "hidden")} />
      </button>

      {searchOpen ? (
        <SearchResults
          onClose={handleClose}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          query={query}
          setQuery={setQuery}
        />
      ) : null}
    </div>
  );
}

export default SearchButton;
