import care_bleaching_no from "./bleaching/care_bleaching_no.svg";
import care_bleaching_oxygene from "./bleaching/care_bleaching_oxygene.svg";
import care_bleaching from "./bleaching/care_bleaching.svg";

import care_drying_drip from "./drying/care_drying_drip.svg";
import care_drying_flat from "./drying/care_drying_flat.svg";
import care_drying_line from "./drying/care_drying_line.svg";
import care_drying_shade from "./drying/care_drying_shade.svg";

import care_ironing_no from "./ironing/care_ironing_no.svg";
import care_ironing_one from "./ironing/care_ironing_one.svg";
import care_ironing_three from "./ironing/care_ironing_three.svg";
import care_ironing_two from "./ironing/care_ironing_two.svg";

import care_professional_f from "./professional/care_professional_f.svg";
import care_professional_f_mild from "./professional/care_professional_f_mild.svg";
import care_professional_no from "./professional/care_professional_no.svg";
import care_professional_p from "./professional/care_professional_p.svg";
import care_professional_p_mild from "./professional/care_professional_p_mild.svg";
import care_professional_w from "./professional/care_professional_w.svg";
import care_professional_w_mild from "./professional/care_professional_w_mild.svg";
import care_professional_w_very_mild from "./professional/care_professional_w_very_mild.svg";

import care_tumble_drying_no from "./tumble_drying/care_tumble_drying_no.svg";
import care_tumble_drying_one from "./tumble_drying/care_tumble_drying_one.svg";
import care_tumble_drying_two from "./tumble_drying/care_tumble_drying_two.svg";

import care_washing_30_mild from "./washing/care_washing_30_mild.svg";
import care_washing_30_very_mild from "./washing/care_washing_30_very_mild.svg";
import care_washing_30 from "./washing/care_washing_30.svg";
import care_washing_40_mild from "./washing/care_washing_40_mild.svg";
import care_washing_40_very_mild from "./washing/care_washing_40_very_mild.svg";
import care_washing_40 from "./washing/care_washing_40.svg";
import care_washing_50_mild from "./washing/care_washing_50_mild.svg";
import care_washing_50 from "./washing/care_washing_50.svg";
import care_washing_60_mild from "./washing/care_washing_60_mild.svg";
import care_washing_60 from "./washing/care_washing_60.svg";
import care_washing_70 from "./washing/care_washing_70.svg";
import care_washing_95_mild from "./washing/care_washing_95_mild.svg";
import care_washing_95 from "./washing/care_washing_95.svg";
import care_washing_hand from "./washing/care_washing_hand.svg";
import care_washing_no from "./washing/care_washing_no.svg";
import care_washing from "./washing/care_washing.svg";

export const bleaching = {
  no: care_bleaching_no,
  oxygene: care_bleaching_oxygene,
  bleaching: care_bleaching,
};

export const drying = {
  drip: care_drying_drip,
  flat: care_drying_flat,
  line: care_drying_line,
  shade: care_drying_shade,
};

export const ironing = {
  no: care_ironing_no,
  one: care_ironing_one,
  three: care_ironing_three,
  two: care_ironing_two,
};

export const professional = {
  f: care_professional_f,
  f_mild: care_professional_f_mild,
  no: care_professional_no,
  p: care_professional_p,
  p_mild: care_professional_p_mild,
  w: care_professional_w,
  w_wild: care_professional_w_mild,
  w_very_mild: care_professional_w_very_mild,
};

export const tumbleDrying = {
  no: care_tumble_drying_no,
  one: care_tumble_drying_one,
  two: care_tumble_drying_two,
};

export const washing = {
  "30": care_washing_30,
  "30_mild": care_washing_30_mild,
  "30_very_mild": care_washing_30_very_mild,
  "40": care_washing_40,
  "40_mild": care_washing_40_mild,
  "40_very_mild": care_washing_40_very_mild,
  "50": care_washing_50,
  "50_mild": care_washing_50_mild,
  "60": care_washing_60,
  "60_mild": care_washing_60_mild,
  "70": care_washing_70,
  "95": care_washing_95,
  "95_mild": care_washing_95_mild,
  "hand": care_washing_hand,
  "no": care_washing_no,
  "washing": care_washing,
};
