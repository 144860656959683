import React, { useMemo } from 'react';

import { withAllStories } from "../../queries";

import StoryPreview from "../../parts/StoryPreview";

const BaseIndex = props => {
  const { allSanityStory } = props;
  const stories = useMemo(() => allSanityStory.edges.map(edge => edge.node), [allSanityStory]);

  return stories.map((story, i) => (
    <StoryPreview
      key={i}
      story={story}
      imageOnTheRight={i % 2 === 0}
    />
  ));
}

const AllIndex = withAllStories(BaseIndex);

const StoryIndex = (props) => {
  return (
    <div className="">
      <AllIndex />
    </div>
  );
};

export default StoryIndex;
