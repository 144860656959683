import React, { useMemo } from 'react'
import clsx from 'clsx'
import { Link } from 'gatsby'

import logo1 from '../../images/depalma_logos-01.svg'
import logoWorkwearWhite from '../../images/depalma_logos-02-white.svg'

import Navigation from './Navigation'
import ShoppingCartButton from './ShoppingCartButton'
import SearchButton from './SearchButton'

import AccountButton from './AccountButton'

const HeaderBar = (props) => {
  const {
    lang,
    logout,
    menuIsOpen,
    nav,
    page,
    setMenuIsOpen,
    setSignInUp,
    site,
    user,
  } = props

  const rootPath = useMemo(
    () => (site._id === 'global' ? '/' : `/${site.slug.current}/${lang}/`),
    [site, lang],
  )

  const isProductPage =
    page?._type === 'productPage' || page?._type === 'productPageVariant'
  const headerTheme = page?.pageDesign?.headerTheme

  return (
    <nav
      id="sticky-header"
      className={clsx('flex items-center text-sm h-16 lg:h-24', {
        'container mx-auto': !isProductPage,
        'text-depalmaWhite': headerTheme === 'white',
      })}
    >
      <div className="flex-1 flex">
        <div className="flex-initial text-xl lg:hidden">
          <button
            className={clsx('hamburger relative block focus:outline-none', {
              open: menuIsOpen,
            })}
            type="button"
            onClick={() => setMenuIsOpen(!menuIsOpen)}
          >
            <span className="hamburger__top-bun"></span>
            <span className="hamburger__bottom-bun"></span>
          </button>
        </div>

        <div className="flex-initial text-xl flex items-center lg:hidden relative ml-4">
          <SearchButton headerTheme={headerTheme} menuIsOpen={menuIsOpen} />
        </div>

        <div className="hidden lg:block">
          <Navigation nav={nav} lang={lang} site={site} />
        </div>
      </div>

      <div className="flex-0 text-center flex items-center">
        <Link to={rootPath}>
          <div
            className={clsx({
              'hidden show-on-sticky': headerTheme === 'white' && !menuIsOpen,
            })}
          >
            <img
              src={logo1}
              alt="DePalma Logo"
              style={{ height: '1.5rem' }}
              className={clsx('h-8 lg:h-10')}
            />
          </div>
          <div
            className={clsx(
              headerTheme === 'white' && !menuIsOpen
                ? 'hide-on-sticky'
                : 'hidden',
            )}
          >
            <img
              src={logoWorkwearWhite}
              alt="DePalma Logo"
              style={{ height: '1.5rem', marginBottom: '1rem' }}
              className={clsx('h-8 lg:h-10')}
            />
          </div>
        </Link>
      </div>

      <div className="flex-1 text-right flex items-center justify-end leading-tight">
        <div className="flex-initial text-xl hidden lg:block ml-4">
          <SearchButton headerTheme={headerTheme} menuIsOpen={menuIsOpen} />
        </div>

        {/* <div className="flex-initial text-xl hidden sm:block">
          <button className="focus:outline-none" onClick={() => {}}>
            <img src={HeartBlack} alt={'Heart'} className={clsx("h-6", headerTheme === 'white' && !menuIsOpen ? "show-on-sticky hidden" : '')} />
            <img src={HeartWhite} alt={'Heart'} className={clsx("h-6", headerTheme === 'white' && !menuIsOpen ? "hide-on-sticky" : "hidden")} />
          </button>
        </div> */}

        <div className="flex-initial text-xl ml-4">
          <ShoppingCartButton
            headerTheme={headerTheme}
            menuIsOpen={menuIsOpen}
          />
        </div>

        <div className="flex-initial text-xl ml-4">
          <AccountButton
            headerTheme={headerTheme}
            menuIsOpen={menuIsOpen}
            logout={logout}
            setSignInUp={setSignInUp}
            user={user}
          />
        </div>
      </div>
    </nav>
  )
}

export default HeaderBar
