import React from "react";
import clsx from "clsx";
import { FormattedMessage } from "react-intl";

import {
  PageLink,
  ResponsiveImage,
} from "@happy-rabbit/gatsby-sanity-cms/components";
import {
  getLocaleValue,
  LocaleBlockContent,
  LocaleString,
  LocaleText,
  combineLocale,
  useCMSContext,
} from "@happy-rabbit/gatsby-sanity-cms";

const StoryPreview = props => {
  const { story, imageOnTheRight = true } = props;

  const { pageConfig, previewImage, previewSummary, previewTitle } = story;
  const { lang } = useCMSContext();

  const image = previewImage || pageConfig?.pageSeo?.image;
  const title = combineLocale([previewTitle, pageConfig?.pageSeo?.title]);
  const summary = combineLocale([
    previewSummary,
    pageConfig?.pageSeo?.description,
  ]);

  return (
    <div className="my-8 flex flex-col lg:flex-row">
      <div
        className={clsx(
          "lg:w-3/5 mb-4 lg:mb-0",
          { "lg:order-2": imageOnTheRight },
          imageOnTheRight ? "lg:ml-16" : "lg:mr-16"
        )}
      >
        {image ? (
          <ResponsiveImage
            alt={
              getLocaleValue(image.altText, lang) || getLocaleValue(title, lang)
            }
            boxRatio={{ xs: "1:1", xl: "4:3" }}
            image={image}
          />
        ) : null}
      </div>
      <div
        className={clsx("lg:w-2/5 flex px-6 sm:px-0 justify-center flex-col", {
          "lg:order-1": imageOnTheRight,
        })}
      >
        <h6 className="text-md uppercase averta mb-8">
          <FormattedMessage id="storyPreview.craftsmen-stories" />
        </h6>
        <h2 className="text-4xl md:text-6xl averta-extrabold leading-none mb-4">
          <PageLink pageConfig={pageConfig}>
            <LocaleString value={title} />
          </PageLink>
        </h2>
        <div className="mb-6">
          {typeof summary[lang] === "string" ? (
            <LocaleText value={summary} />
          ) : (
            <LocaleBlockContent value={summary} />
          )}
        </div>
        <div className="mb-2">
          <PageLink
            buttonTheme="ghost"
            showAsButton={true}
            title={
              <FormattedMessage
                id="storyPreview.read-more"
                defaultMessage="Read more"
              />
            }
            pageConfig={pageConfig}
          />
          <br />
        </div>
      </div>
    </div>
  );
};

export default StoryPreview;
