import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import GoTrue from 'gotrue-js';

const reduceHashToKeyValue = hash =>
  hash.split('&').reduce((carry, pair) => {
    const [key, value] = pair.split('=');

    return { ...carry, [key]: value };
  }, {});

const defaultParam = {
  token: undefined,
  type: undefined,
  error: undefined,
  status: undefined,
};

const hashReplace = /^#\/?/;
const routes = /(confirmation|invite|recovery|email_change|access)_token=([^&]+)/;

const runRoutes = (gotrue, setUser, remember = true) => {
  if (!document?.location?.hash) {
    return defaultParam;
  }
  const hash = document.location.hash.replace(hashReplace, '');

  // Remove the param from the history so that navigation back and forward does not trigger any routes again
  try {
    history.pushState(
      '',
      document.title,
      window.location.pathname + window.location.search
    );
  } catch (_) {
    window.location.href.substr(0, window.location.href.indexOf('#'));
  }

  const matchesActionHashes = hash.match(routes);

  if (matchesActionHashes) {
    const params = reduceHashToKeyValue(hash);

    if (params.confirmation_token) {
      gotrue
        .confirm(params.confirmation_token, remember)
        .then(setUser)
        .catch(console.error);

      // dont notify dev as this package does not export its own method for this
      return defaultParam;
    }

    if (params.access_token) {
      document.cookie = `nf_jwt=${params.access_token}`;

      gotrue
        .createUser(params, remember)
        .then(setUser)
        .catch(console.error);

      // also dont notify dev here for the same reasons as above
      return defaultParam;
    }

    // pass responsibility to dev in all other cases
    return {
      ...defaultParam,
      type: matchesActionHashes[1],
      token: matchesActionHashes[2],
    };
  }
}

const auth = new GoTrue({
  APIUrl: 'https://depalma-workwear.netlify.app/.netlify/identity',
  audience: '',
  setCookie: false,
});

const IdentityStateContext = createContext(null);

const IdentityProvider = props => {
  const { children, enableRunRoutes = false } = props;
  
  const [user, setUser] = useState(auth.currentUser() || undefined);
  const [param, setParam] = useState(defaultParam);

  useEffect(() => {
    if (enableRunRoutes) {
      const param = runRoutes(auth, setUser);

      if (param.token || param.error) {
        setParam(param);
      }
    }
  }, []);

  const login = useCallback((email, password, remember) => {
    return new Promise((resolve, reject) => {
      auth.login(email, password, remember)
        .then(res => {
          setUser(res);
          resolve('success');
        })
        .catch(err => {
          if (err?.name === 'JSONHTTPError') {
            reject(err.json);
          } else {
            reject(err)
          }
        });
    });
  }, [auth]);

  const logout = useCallback(() => {
    return new Promise((resolve, reject) => {
      user.logout()
        .then(res => {
          setUser(null);
          resolve();
        });
    });
  }, [user]);

  const acceptInvite = useCallback((token, password, remember) => {
    return new Promise((resolve, reject) => {
      auth.acceptInvite(token, password, remember)
        .then(res => {
          setUser(res);
          resolve('success')
        })
        .catch(err => {
          if (err?.name === 'JSONHTTPError') {
            reject(err.json);
          } else {
            reject(err)
          }
        })
    });
  }, [auth])

  const updateUser = useCallback(attributes => {
    return new Promise((resolve, reject) => {
      user.update(attributes)
        .then(res => {
          setUser(res);
          resolve('success');
        })
        .catch(err => {
          if (err?.name === 'JSONHTTPError') {
            reject(err.json);
          } else {
            reject(err)
          }
        })
    });
  }, [user]);


  const context = {
    acceptInvite,
    auth,
    login,
    logout,
    param,
    updateUser,
    user
  };
  
  return (
    <IdentityStateContext.Provider value={context}>
      {children}
    </IdentityStateContext.Provider>
  );
};

export const useIdentityStateContext = () => {
  const context = useContext(IdentityStateContext);

  if (context === undefined) {
    throw new Error('useIdentityStateContext must be used within a IdentityProvider');
  }

  return context;
};

export default IdentityProvider;
