import React, { useEffect, useState } from "react";
import { navigate } from "gatsby";
import axios from "axios";
import { FormattedMessage, IntlProvider } from "react-intl";
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies";
import { useCMSContext } from "@happy-rabbit/gatsby-sanity-cms";
import { PageLink } from "@happy-rabbit/gatsby-sanity-cms/components";
import { Button } from "@happy-rabbit/component-library";

import { withCountries, withSites } from "../../queries";
import { setCookie } from "../../utils/cookies";
import { useCountryStateContext } from "../../contexts/CountryContext";
import translations from "../../translations";
import Modal from "../../components/Modal";

import RegionCountryLang from "./RegionCountryLang";
import ConfirmButtons from "./ConfirmButtons";
import { useSiteLang } from "./hooks";
import NotificationCompanyEurope from "./NotificationCompanyEurope";
import NotificationIndividualNorway from "./NotificationIndividualNorway";
import NotificationIndividualUK from "./NotificationIndividualUK";

const renderNotification = target => {
  if (target === "companyEurope") {
    return <NotificationCompanyEurope />;
  }

  if (target === "individualNO") {
    return <NotificationIndividualNorway />;
  }

  if (target === "individualUK") {
    return <NotificationIndividualUK />;
  }
};

const SiteLangSelector = props => {
  const {
    allSanityCountry: { edges: countryEdges = [] },
    allSanitySite: { edges: siteEdges = [] },
    onClose,
  } = props;

  // Context variables
  const { pageConfig, lang, site } = useCMSContext();
  const {
    country,
    setCountry,
    setIncludeVAT,
    setBehaviour,
  } = useCountryStateContext();

  // State variables
  const [notifyBeforeClose, setNotifyBeforeClose] = useState(null);
  const [profile, setProfile] = useState(null);

  const [selectedRegion, setSelectedRegion] = useState(country?.region);
  const [selectedCountry, setSelectedCountry] = useState(country);
  const [selectedLanguage, setSelectedLanguage] = useState(lang);

  // Memoized variables
  const {
    countryBySlug,
    listOfCountries,
    listOfRegions,
    regionById,
    sitesById,
  } = useSiteLang({ countryEdges, siteEdges });

  const setBySlug = countrySlug => {
    const slug = countrySlug.toLowerCase();
    const country = countryBySlug[slug];

    if (country) {
      setSelectedRegion(regionById[country.region._id]);
      setSelectedCountry(country);
      setSelectedLanguage(slug === "se" ? "sv" : "en");
    }
  };

  // Effects
  useEffect(() => {
    if (
      site._id === "global" &&
      !country &&
      typeof navigator !== `undefined` &&
      navigator.language?.split
    ) {
      if (navigator.language.split("-")[0] === "sv") {
        setBySlug("se");
      } else {
        axios
          .get(`/.netlify/functions/getIpInfo`)
          .then(({ data }) => data.country && setBySlug(data.country))
          .catch(console.log);
      }
    }
  }, []);

  // Handlers
  const handleRegionChange = option => {
    const newRegion = regionById[option?.value] || null;
    setSelectedRegion(newRegion);

    if (selectedCountry?.region?._id !== newRegion?._id) {
      setSelectedCountry(null);
      setSelectedLanguage("en");
    }
  };

  const handleCountryChange = option => {
    const newCountry = countryBySlug[option?.value] || null;

    setSelectedCountry(newCountry || null);
    setSelectedLanguage(newCountry?.defaultLanguage || "en");
  };

  const handleLanguageChange = option => {
    setSelectedLanguage(option?.value || null);
  };

  const handleConfirm = (profile = null) => {
    const {
      slug: { current: pagePath },
    } = pageConfig || {};
    const language = selectedLanguage;

    const asIndividual = profile === "individual";
    const asCompany = profile === "company";

    // Cannot confirm selection if either country or region
    // is missing.
    if (!selectedCountry || !selectedRegion) return;

    // If notifyBeforeClose is not set, check to see if the
    // selection of country and region would prompt a notification
    // from the user.
    if (!notifyBeforeClose) {
      if (
        asCompany &&
        selectedRegion.name.en === "Europe" &&
        selectedCountry.name.en !== "Sweden"
      ) {
        setProfile(profile);
        setNotifyBeforeClose("companyEurope");
        return;
      } else if (asIndividual && selectedCountry.slug.current === "no") {
        setProfile(profile);
        setNotifyBeforeClose("individualNO");
        return;
      } else if (asIndividual && selectedCountry.slug.current === "gb") {
        setProfile(profile);
        setNotifyBeforeClose("individualUK");
        return;
      }
    }

    const selectedSite = sitesById[selectedCountry.site._id];
    const prefix =
      selectedSite._id === "global"
        ? ""
        : `/${selectedSite.slug.current}/${language}`;

    setCountry(selectedSite, selectedCountry, prefix);
    setIncludeVAT(asIndividual);
    if (profile) {
      setBehaviour({
        [`${profile}Selected`]: true,
        lastProfileSelected: profile,
      });
    }

    // By confirming this dialogue, cookie policy is accepted by user
    setCookie("gatsby-gdpr-google-tagmanager", "true");

    if (selectedSite._id === site._id && language === lang) {
      // No change in site or lang, stay on same page, but make sure that gtm is initialized
      initializeAndTrack(window.location);
      onClose();
    } else {
      navigate(`${prefix}${pagePath}`);
    }
  };

  return (
    <Modal
      hasConfirm={false}
      hasClose={false}
      hideHeader={true}
      onClose={() => {}}
      title={"Select Country and Language"}
    >
      <IntlProvider
        defaultLocale="en"
        locale={selectedLanguage || "en"}
        messages={translations[selectedLanguage || "en"]}
      >
        {notifyBeforeClose ? (
          <div className="text-center">
            {renderNotification(notifyBeforeClose)}
            <div className="mb-8">
              <Button
                theme="black"
                onClick={() => handleConfirm(profile)}
                style={{ transition: "all .15s ease" }}
                type="button"
              >
                {notifyBeforeClose === "individualNO" ||
                notifyBeforeClose === "individualUK" ? (
                  <FormattedMessage
                    id="siteLangSelector.continue"
                    defaultMessage="Continue"
                  />
                ) : (
                  <FormattedMessage
                    id="siteLangSelector.take-me-shopping"
                    defaultMessage="Take me shopping"
                  />
                )}
              </Button>
            </div>
          </div>
        ) : (
          <div className="">
            {/* <h2 className="averta-bold text-3xl pb-6 text-center leading-tight">
              <FormattedMessage
                id="siteLangSelector.welcome"
                description="Welcome Message"
                defaultMessage={`Welcome to`}
              />
              <br/>
              DePalma Workwear
            </h2> */}

            <RegionCountryLang
              listOfCountries={listOfCountries}
              listOfRegions={listOfRegions}
              selectedCountry={selectedCountry}
              selectedLanguage={selectedLanguage}
              selectedRegion={selectedRegion}
              onCountryChange={handleCountryChange}
              onLanguageChange={handleLanguageChange}
              onRegionChange={handleRegionChange}
              sitesById={sitesById}
            />

            <p className="text-xs text-center mt-8 mb-2 px-8">
              <FormattedMessage
                id="siteLangSelector.gdpr-message.part-1"
                description="GDPR message"
                defaultMessage={`We use cookies in order to personalize your experience and offer you more relevant content.`}
              />
            </p>

            <p className="text-xs text-center mb-8 px-8">
              <FormattedMessage
                id="siteLangSelector.gdpr-message.part-2"
                description="GDPR message"
                defaultMessage={`By clicking any of the buttons below you agree to our {toc} and Privacy Policy, including the use of cookies.`}
                values={{
                  terms: (
                    <PageLink
                      pageConfig={{ slug: { current: "/terms" } }}
                      className="underline"
                    >
                      <FormattedMessage
                        id="siteLangSelector.gdpr-message.terms"
                        defaultMessage={`Terms & Conditions`}
                      />
                    </PageLink>
                  ),
                  policy: (
                    <PageLink
                      pageConfig={{ slug: { current: "/privacy" } }}
                      className="underline"
                    >
                      <FormattedMessage
                        id="siteLangSelector.gdpr-message.policy"
                        defaultMessage={`Privacy Policy`}
                      />
                    </PageLink>
                  ),
                }}
              />
            </p>

            <ConfirmButtons
              onConfirm={handleConfirm}
              selectedRegion={selectedRegion}
              selectedLanguage={selectedLanguage}
            />
          </div>
        )}
      </IntlProvider>
    </Modal>
  );
};

export default withCountries(withSites(SiteLangSelector));
