const productIdToGid = id => `gid://shopify/Product/${id}`
const gidToId = id => Math.floor(id.split('/').slice(-1).join())

export async function getVariantStatus(client, selectedProduct) {
  if (!selectedProduct) return
  const productId = productIdToGid(selectedProduct?.externalReferences[0]?.product_id)
  try {
    const product = await client.product.fetch(productId)
    const availableProductVariants = product.variants.map(variant => {
      return {
        shopify_variant_id: gidToId(variant.id),
        available: variant.available
      }
    })
    return availableProductVariants

  } catch (e) {
    return []
  }

}
export async function getCategoryVariantStatus(client, categoryProducts) {
  if (!categoryProducts) return

  const productIds = categoryProducts.reduce((acc, i) => {
    const extRef = i.product.productVariants.find(v => v.externalReferences.length).externalReferences
    const product_id = productIdToGid(extRef[0]?.product_id)
    if (acc.includes(product_id)) return acc
    acc.push(product_id)
    return acc
  }, [])


  const sanityVariantsIdsMap = categoryProducts.reduce((acc, i) => {
    const currentVariants = i.product.productVariants.filter(v => v.productColorway._id === i.productColorway._id)
    acc[i._id] = currentVariants.map(v => v.externalReferences[0]?.variant_id)
    return acc
  }, {})

  try {
    const products = await client.product.fetchMultiple(productIds)

    const variantsAvailable = products.reduce((acc, p) => {
      if (!p?.variants) return acc
      for (let i = 0; i < p.variants.length; i++) {
        acc[gidToId(p.variants[i].id)] = p.variants[i].available
      }
      return acc
    }, {})

    const productsAvailable = Object.entries(sanityVariantsIdsMap).reduce((acc,i) => {
      const hasAnyVariants = i[1].map(id => variantsAvailable[id]).includes(true)
      acc[i[0]] = hasAnyVariants
      return acc
    },[])

    const updatedCategoryProducts = categoryProducts.map(product => {
      product['isAvailable'] = productsAvailable[product._id]
      return product
    })

    return updatedCategoryProducts

  } catch (e) {
    return categoryProducts
  }

}
