export const copiedFromBrowserLog = {
  _key: 'f7247c1c3b90',
  _type: 'pageSections.form',
  inclTitle: false,
  netlifyForm: {
    _id: 'ed14fe93-4166-4449-87b0-aaa40eb78656',
    _type: 'netlifyForm',
    dripFormId: null,
    formFields: [
      {
        _key: '6cf2795aaa0f',
        _type: 'formInput',
        fieldId: {
          _key: null,
          _type: 'slug',
          current: 'contact_1_name_first',
        },
        fieldType: 'text',
        label: {
          en: 'First Name',
          sv: 'Förnamn',
        },
        required: true,
      },
      {
        _key: '8fb267b83166',
        _type: 'formInput',
        fieldId: {
          _key: null,
          _type: 'slug',
          current: 'contact_1_name_last',
        },
        fieldType: 'text',
        label: {
          en: 'Last Name',
          sv: 'Efternamn',
        },
        required: true,
      },
      {
        _key: '8048868c933d',
        _type: 'formInput',
        fieldId: {
          _key: null,
          _type: 'slug',
          current: 'company_name',
        },
        fieldType: 'text',
        label: {
          en: 'Company Name (if applicable) ',
          sv: 'Företagsnamn (eventuellt)',
        },
        required: null,
      },
      {
        _key: '701b713be074',
        _type: 'formInput',
        fieldId: {
          _key: null,
          _type: 'slug',
          current: 'email',
        },
        fieldType: 'email',
        label: {
          en: 'Email Address',
          sv: 'Emailadress',
        },
        required: true,
      },
      {
        _key: 'fec9716b349e',
        _type: 'formToggle',
        defaultActive: null,
        fieldId: {
          _key: null,
          _type: 'slug',
          current: 'consent',
        },
        fieldType: 'checkbox',
        label: {
          en:
            'I hereby accept that my personal information is registered electronically to be used for marketing purposes.',
          sv:
            'Härmed godkänner jag att min personliga information registreras elektroniskt och kan användas i marknadsföringssyften. ',
        },
        valueActive: null,
        valueInactive: null,
      },
    ],
    formId: {
      _key: null,
      _type: 'slug',
      current: 'membership-application',
    },
    submitText: {
      en: 'Send',
      sv: 'Skicka',
    },
    target: 'netlify',
    title: {
      en: 'Membership application',
      sv: 'Medlemskapsansökan',
    },
  },
  sectionDesign: {
    _key: null,
    _type: 'pageSections.design',
    alignItems: null,
    bgColor: null,
    bgImage: null,
    bgInsideContainer: null,
    contentInsideContainer: null,
    height: null,
    justifyContent: null,
    padding: {
      _key: null,
      _type: 'responsive.padding',
      lg: null,
      md: null,
      sm: null,
      xl: null,
      xs: {
        bottom: '-10',
        left: '-0',
        right: '-0',
        top: null,
      },
    },
    textColor: null,
    themeReferences: [],
  },
}
