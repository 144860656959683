import React from "react";
import { useCMSContext } from "@happy-rabbit/gatsby-sanity-cms";
import ModifiedProductGrid from "../ModifiedProductGrid";
const ProductByCategoryIndex = props => {
  const { pageSection } = props;

  const { productPageVariants } = useCMSContext();

  return (
    <ModifiedProductGrid productPageVariants={productPageVariants}/>
  );
};

export default ProductByCategoryIndex;
