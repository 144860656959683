import React, { useMemo } from "react";
// import { idOrRef } from "../../utils/productScope";
import ProductThumbnail from "../ProductThumbnail";

const ProductGrid = props => {
  const { productPageVariants } = props;

  // const sorted = useMemo(() => productPageVariants.slice().sort((a,b) => {
  //   const { product: aProduct, productColorway: aProductColorway } = a;
  //   const { product: bProduct, productColorway: bProductColorway } = b;

  //   // Uses same method as useProductScope to calculate allOutOfStock, but cannot use
  //   // a hook inside a dynamic filter since the length of array could potentially change.
  //   const aPossibleProductVariants = aProduct.productVariants.filter(
  //     v => idOrRef(v.productColorway) === idOrRef(aProductColorway)
  //   );

  //   const bPossibleProductVariants = bProduct.productVariants.filter(
  //     v => idOrRef(v.productColorway) === idOrRef(bProductColorway)
  //   );

  //   const aAllOutOfStock = aPossibleProductVariants.every(
  //     productVariant =>
  //       (productVariant.inventoryStatus || "out-of-stock") === "out-of-stock"
  //   );

  //   const bAllOutOfStock = bPossibleProductVariants.every(
  //     productVariant =>
  //       (productVariant.inventoryStatus || "out-of-stock") === "out-of-stock"
  //   );

  //   if (aAllOutOfStock !== bAllOutOfStock) {
  //     return aAllOutOfStock ? 1 : -1;

  //   } else if (aProduct.code === bProduct.code) {
  //     return aProductColorway.code == bProductColorway.code ? 0 : aProductColorway.code > bProductColorway.code ? 1 : -1;

  //   } else {
  //     return aProduct.code == bProduct.code ? 0 : aProduct.code > bProduct.code ? 1 : -1;
  //   }
  // }), [productPageVariants])

  return (
    <div
      className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4"
      role="grid"
    >
      {productPageVariants.map((productPageVariant, i) => (
        <ProductThumbnail
          key={i}
          noOfColsArr={[2,2,3,4,4]}
          productPage={productPageVariant.mainPage}
          productPageVariant={productPageVariant}
        />
      ))}
    </div>
  );
};

export default ProductGrid;
