import React from 'react';

import SizeGuideTable from "../../parts/SizeGuideTable";


const SizeGuide = (props) => {
  const { pageSection } = props;
  const { sizeGuide } = pageSection || {};

  return (
    <div className="flex flex-col">
      {sizeGuide ? (
        <SizeGuideTable sizeGuide={sizeGuide} />
      ) : null}
    </div>
  );
};

export default SizeGuide;
