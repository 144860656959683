import React, { useCallback, useState } from "react";
import { FormattedMessage } from "react-intl";

import { useCountryStateContext } from "../../contexts/CountryContext";
import { useProductScope } from "../../utils/productScope";

import { PageLink } from "@happy-rabbit/gatsby-sanity-cms/components";
import { LocaleString } from "@happy-rabbit/gatsby-sanity-cms";
import Price from "../../components/Price";
import Photo from "./Photo";

import HeartBlack from "../../images/heart_black.svg";

const ProductThumbnail = props => {
  const { noOfColsArr, productPageVariant } = props;

  const { isAvailable } = productPageVariant;

  const {
    discontinued,
    pageConfig,
    product,
    productColorway,
  } = productPageVariant;

  const {
    allOutOfStock,
    possibleProductVariants,
    selectedVariant,
    scopedProductPhotos,
  } = useProductScope(product, productColorway);

  const { currencyCode } = useCountryStateContext();

  const [hover, setHover] = useState(false);

  const productPhoto = scopedProductPhotos[0];
  const secondaryPhoto = scopedProductPhotos[1];
  const price = ((selectedVariant || possibleProductVariants[0])?.price || {})[
    currencyCode?.toLowerCase()
  ];

  const handleClick = useCallback(e => {
    try {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push(function () {
        this.reset();
      });
      window.dataLayer.push({
        event: "eec.productClick",
        ecommerce: {
          currencyCode,
          click: {
            // actionField: {
            //   list: 'Product View',
            // },
            products: [
              {
                id: product.code,
                name: [
                  [product.name, product.description?.en]
                    .filter(Boolean)
                    .join(" "),
                  productColorway.name?.en,
                ]
                  .filter(Boolean)
                  .join(" / "),
                dimension2: productColorway?.name?.en || "",
              },
            ],
          },
        },
      });
    } catch (e) {
      console.log(e);
    }
  }, []);

  return (
    <div className="mb-4">
      <PageLink
        className="relative"
        onMouseOver={() => setHover(true)}
        onMouseOut={() => setHover(false)}
        onFocus={() => setHover(true)}
        onBlur={() => setHover(false)}
        onClick={handleClick}
        pageConfig={pageConfig}
      >
        <Photo
          mainPhoto={productPhoto}
          noOfColsArr={noOfColsArr}
          secondaryPhoto={secondaryPhoto}
        >
          {/* <div
            className={clsx("absolute top-0 right-0 p-1", { hidden: !hover })}
          >
            <button className="focus:outline-none" onClick={() => {}}>
              <img src={HeartBlack} alt={"Heart"} className="h-4" />
            </button>
          </div> */}

          {/* <div
            className={clsx(
              "absolute bottom-0 left-0 right-0 p-1 text-center",
              { hidden: !hover }
            )}
          >
            <PageLink
              className="mx-auto mb-4"
              pageConfig={pageConfig}
              buttonTheme="black"
              showAsButton={true}
            >
              <FormattedMessage
                id="productThumbnail.shop-now"
                defaultMessage="Shop Now"
              />
            </PageLink>
          </div> */}

          {isAvailable !== undefined && !isAvailable && !discontinued ? (
            <div className="absolute top-0 left-0 w-full h-full flex flex-col justify-center items-stretch">
              <div className="w-full bg-depalmaWhite bg-opacity-75 text-center py-4">
                {discontinued ? (
                  <FormattedMessage
                    id="productThumbnail.discontinued"
                    defaultMessage="Discontinued"
                  />
                ) : (
                  <FormattedMessage
                    id="productThumbnail.temporarily-out-of-stock"
                    defaultMessage="Temporary Out of Stock"
                  />
                )}
              </div>
            </div>
          ) : null}
        </Photo>
      </PageLink>

      <div className="mt-2 text-xs leading-tight px-6 sm:px-0">
        <PageLink onClick={handleClick} pageConfig={pageConfig}>
          <h3>
            <span className="averta-bold">{product.name}</span>{" "}
            <LocaleString value={product.description} />
          </h3>
        </PageLink>
        <h3 className="text-depalmaDarkGray">
          <LocaleString value={productColorway.name} />
        </h3>
        {price && !discontinued ? (
          <h4>
            {price.compareToPrice ? (
              <>
                <span className="text-depalmaRed">
                  <Price
                    amount={price.amount}
                    currencyCode={price.currencyCode}
                    recalculateForVAT={true}
                  />
                </span>{" "}
                <span className="line-through ml-4">
                  <Price
                    amount={price.compareToPrice}
                    currencyCode={price.currencyCode}
                    recalculateForVAT={true}
                  />
                </span>
              </>
            ) : (
              <Price {...price} recalculateForVAT={true} />
            )}
          </h4>
        ) : null}
      </div>
    </div>
  );
};

export default ProductThumbnail;
