import React from "react";
import clsx from "clsx";

import Navigation from "./Navigation";
import { useCMSContext } from "@happy-rabbit/gatsby-sanity-cms";


const Menu = props => {
  const {
    isOpen,
    nav,
  } = props;

  const { lang, site } = useCMSContext();

  return (
    <div
      id="menu"
      className={clsx(
        "w-full lg:w-auto self-end lg:self-center flex-col lg:flex-row items-center h-full py-1 pb-4 lg:py-0 lg:pb-0",
        { hidden: !isOpen, flex: isOpen }
      )}
    >
      <Navigation nav={nav} lang={lang} site={site} />
    </div>
  );
};

export default Menu;
