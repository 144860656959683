import React from "react";
import { FormattedMessage } from "react-intl";

const NotificationCompanyEurope = props => {
  return (
    <>
      <p className="mb-2">
        <FormattedMessage
          id="siteLangSelector.notification-eu-outside-sweden.part-1"
          description="Notification message"
          defaultMessage={`Dear Customer,`}
        />
      </p>

      <p className="mb-2">
        <FormattedMessage
          id="siteLangSelector.notification-eu-outside-sweden.part-2"
          description="Notification message"
          defaultMessage={`We are in the middle of relaunching our website featuring a whole new webshop and unfortunately, we can’t yet offer a seamless experience for VAT-free sales to companies within the EU, but outside of Sweden, through our webshop checkout.`}
        />
      </p>

      <p className="mb-2">
        <FormattedMessage
          id="siteLangSelector.notification-eu-outside-sweden.part-3"
          description="Notification message"
          defaultMessage={`If you have an EU VAT number and want to place an order, please select Quotation as the payment method at checkout and our customer support team will contact you to finalise your order.`}
        />
      </p>
      <p className="mb-2">
        <FormattedMessage
          id="siteLangSelector.notification-eu-outside-sweden.part-4"
          description="Notification message"
          defaultMessage={`If you have any questions, please don’t hesitate to contact us.`}
        />
      </p>
    </>
  );
};

export default NotificationCompanyEurope;
