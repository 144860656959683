import React from "react";
import { FormattedMessage } from "react-intl";
import NewsletterForm from "../../components/NewsletterForm";

const NotificationIndividualNorway = props => {
  return (
    <>
      <p className="mb-2">
        <FormattedMessage
          id="siteLangSelector.notification-eu-outside-sweden.part-1"
          defaultMessage={`Dear Customer,`}
        />
      </p>

      <p className="mb-2">
        <FormattedMessage
          id="siteLangSelector.notification-no-individual"
          defaultMessage={`We are in the middle of relaunching our website featuring a whole new webshop and currently we only sell to businesses in Norway with a valid VAT number. If you want to buy as an individual, please sign up to our newsletter to be notified when that is possible.`}
        />
      </p>

      <p className="mb-2">
        <FormattedMessage
          id="siteLangSelector.notification-eu-outside-sweden.part-4"
          defaultMessage={`If you have any questions, please don’t hesitate to contact us.`}
        />
      </p>

      <div className="mb-4">
        <NewsletterForm
          hideTitle={true}
          formId={'190711949'}
          source={"Country Selector - Individual NO Notification"}
        />
      </div>
    </>
  );
};

export default NotificationIndividualNorway;
