import React, { useCallback, useEffect, useRef } from "react";
import PropTypes from 'prop-types';
import { FormattedMessage } from "react-intl";

import eventStacker from '../../utils/eventStacker';

const Modal = (props) => {
  const {
    children,
    hasAction = false,
    hasClose = true,
    hasConfirm = false,
    hideHeader = false,
    actionLabel = 'Save Changes',
    onClickAction = null,
    onClose,
    onConfirm,
    title = 'Window',
  } = props;

  // Ref for the Modal frame (does not incl. backdrop)
  const ref = useRef();

  useEffect(() => {
    const eventStackId = eventStacker.addListenable({
      // Closes on click outside of the Modal frame
      click: (e) => ref.current && !ref.current.contains(e.target) && onClose(),

      // Closes on Esc key being pressed
      keydown: (e) => e.keyCode === 27 && onClose(),
    });

    return () => eventStacker.removeListenable(eventStackId);
  }, [onClose, ref]);

  const renderHeader = useCallback(() => (
    <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300">
      <h3 className="text-xl averta-semibold">
        {title}
      </h3>
      <button
        className="p-1 ml-auto bg-transparent border-0 opacity-5 float-right text-3xl leading-none averta-semibold outline-none focus:outline-none"
        onClick={onClose}
      >
        <span className="bg-transparent opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
          ×
        </span>
      </button>
    </div>
  ), [onClose, title])

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-999 outline-none focus:outline-none text-depalmaBlack">
        <div className="relative w-auto my-6 mx-auto w-full sm:w-3/4 md:w-1/2 max-h-full max-w-md" ref={ref}>
          {/*content*/}
          <div className="border-0 shadow-lg relative flex flex-col w-full h-full bg-depalmaWhite outline-none focus:outline-none px-6 py-4">
            {/*header*/}
            {hideHeader ? null : renderHeader()}

            {/*body*/}
            <div className="relative p-6 flex-auto">
              {children}
            </div>
            {/*footer*/}
            <div className="flex items-center justify-center px-6 pb-6 border-t border-solid border-gray-300">
              {hasClose ? (
                <button
                  className="text-depalmaBlack bg-depalmaWhite border-depalmaBlack border background-transparent averta-bold uppercase mx-1 px-6 py-2 text-sm outline-none focus:outline-none"
                  type="button"
                  style={{ transition: "all .15s ease" }}
                  onClick={onClose}
                >
                  <FormattedMessage id="modal.close" />
                </button>
              ) : null}
              {hasAction ? (
                <button
                  className="text-depalmaWhite averta-bold mx-1 py-2 px-4 focus:outline-none focus:shadow-outline"
                  onClick={onClickAction}
                  style={{ transition: "all .15s ease" }}
                  type="button"
                >
                  {actionLabel}
                </button>
              ) : null}
              {hasConfirm ? (
                <button
                  className="bg-depalmaBlack text-depalmaWhite averta-bold mx-1 py-2 px-4 focus:outline-none focus:shadow-outline"
                  onClick={onConfirm}
                  style={{ transition: "all .15s ease" }}
                  type="button"
                >
                  <FormattedMessage
                    id="modal.confirm"
                    defaultMessage="Confirm"
                  />
                </button>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
}

Modal.propTypes = {
  onClose:        PropTypes.func.isRequired,
};

export default Modal;
