const algoliasearch = require('algoliasearch');

exports.INDEX_PRODUCT_VARIANTS = 'productPageVariants';

exports.searchClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_KEY,
);

const productPageVariantQuery = `{
  pages: allSanityProductPageVariant {
    edges {
      node {
        ...SanityProductPageVariantPreviewFragment
        id
        _updatedAt
      }
    }
  }
}

fragment SanityBrandColorFragment on SanityBrandColor {
  _id
  _type

  code
  color {
    ...SanityColorFragment
  }
  title {
    ...SanityLocaleStringFragment
  }
}

fragment SanityColorCategoryFragment on SanityColorCategory {
  _id
  _type
  title {
    ...SanityLocaleStringFragment
  }
}

fragment SanityColorComboFragment on SanityColorCombo {
  _id
  _type

  brandColors {
    ...SanityBrandColorFragment
  }
}

fragment SanityColorFragment on SanityColor {
  _key
  _type

  alpha
  hex
}

fragment SanityImageFragment on SanityImage {
  _key
  _type

  hotspot {
    height
    width
    x
    y
  }
  crop {
    bottom
    left
    right
    top
  }
  asset {
    _id
    url
    metadata {
      dimensions {
        height
        width
        aspectRatio
      }
    }
  }
}

fragment SanityLocaleStringFragment on SanityLocaleString {
  en
  sv
}

fragment SanityMultiCurrencyPriceFragment on SanityMultiCurrencyPrice {
  _key
  _type

  eur {
    ...SanityPriceFragment
  }
  gbp {
    ...SanityPriceFragment
  }
  sek {
    ...SanityPriceFragment
  }
  usd {
    ...SanityPriceFragment
  }
}

fragment SanityPriceFragment on SanityPrice {
  _key
  _type

  amount
  compareToPrice
  currencyCode
  priceInclTax
}

fragment SanityProductColorwayFragment on SanityProductColorway {
  _id
  _type

  code
  color {
    ... on SanityBrandColor {
      ...SanityBrandColorFragment
    }
    ... on SanityColorCombo {
      ...SanityColorComboFragment
    }
  }
  colorCategory {
    ...SanityColorCategoryFragment
  }
  image {
    ...SanityWebImageFragment
  }
  name {
    ...SanityLocaleStringFragment
  }
}

fragment SanityProductPageVariantPreviewFragment on SanityProductPageVariant {
  _id
  pageConfig {
    slug {
      current
    }
  }
  product {
    description {
      ...SanityLocaleStringFragment
    }
    name
    productColorways {
      ...SanityProductColorwayFragment
    }
    productImages {
      productPhoto {
        altText {
          ...SanityLocaleStringFragment
        }
        category
        image {
          ...SanityImageFragment
        }
        imageType
        subCategory
      }
      productScope {
        ... on SanityProductColorway {
          _id
          _type
        }
        ... on SanityProductSize {
          _id
          _type
        }
      }
    }
    productSizes {
      _id
      abbreviation
    }
    productVariants {
      externalReferences {
        shopName
        variant_id
      }
      price {
        ...SanityMultiCurrencyPriceFragment
      }
      productColorway {
        _id
      }
      productSize {
        _id
      }
    }
  }
  productColorway {
    _id
    name {
      ...SanityLocaleStringFragment
    }
  }
}

fragment SanityWebImageFragment on SanityWebImage {
  _key
  _type

  altText {
    ...SanityLocaleStringFragment
  }

  hotspot {
    height
    width
    x
    y
  }
  crop {
    bottom
    left
    right
    top
  }
  asset {
    _id
    url
  }
}`;

exports.queries = [
  {
    query: productPageVariantQuery,
    transformer: ({ data }) => data.pages.edges.map(e => e.node), // optional
    indexName: 'productPageVariants', // overrides main index name, optional
    settings: {
      // optional, any index settings
      // Note: by supplying settings, you will overwrite all existing settings on the index
    },
    matchFields: ['slug', '_updatedAt'], // Array<String> overrides main match fields, optional
  },
];
