import React from "react";

import Menu from "./Menu";

const Column = (props) => {
  const {
    nav,
    lang,
    site,
  } = props;

  return (
    <div className="flex-1 mb-8">
      {nav ? (
        <Menu
          lang={lang}
          nav={nav}
          site={site}
        />
      ) : null}
    </div>
  );
};

export default Column;
