import React from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "@happy-rabbit/component-library";


const ConfirmButtons = props => {
  const {
    onConfirm,
    selectedRegion,
  } = props;

  if (selectedRegion?.name?.en === 'Europe') {
    return (
      <>
        <p className="text-xs text-center mt-8 mb-8 px-8">
          <FormattedMessage
            id="siteLangSelector.experience-as"
            description="Experience the website as"
            defaultMessage={`Do you want to experience our website as an individual or on behalf of a company?`}
          />
        </p>
        <div className="flex justify-center">
          <div className="mx-1">
            <Button
              theme="black"
              onClick={() => onConfirm('individual')}
              style={{ transition: "all .15s ease" }}
              type="button"
            >
              <FormattedMessage
                id="siteLangSelector.individual"
                defaultMessage="Individual"
              />
            </Button>

            <p className="text-xs text-center mt-2">
              <FormattedMessage
                id="siteLangSelector.prices-incl-vat"
                defaultMessage="prices including VAT"
              />
            </p>
          </div>

          <div className="mx-1">
            <Button
              theme="black"
              onClick={() => onConfirm('company')}
              style={{ transition: "all .15s ease" }}
              type="button"
            >
              <FormattedMessage
                id="siteLangSelector.company"
                defaultMessage="Company"
              />
            </Button>

            <p className="text-xs text-center mt-2">
              <FormattedMessage
                id="siteLangSelector.prices-excl-vat"
                defaultMessage="prices excluding VAT"
              />
            </p>
          </div>
        </div>
      </>
    );

  } else {
    return (
      <div className="flex justify-center">
        <Button
          theme="black"
          onClick={() => onConfirm(null)}
          style={{ transition: "all .15s ease" }}
          type="button"
        >
          <FormattedMessage
            id="modal.confirm"
            defaultMessage="Confirm"
          />
        </Button>
      </div>
    );
  }
};

export default ConfirmButtons;
