import React from 'react';
import { useCMSContext } from "@happy-rabbit/gatsby-sanity-cms";

import NewsletterForm from '../../components/NewsletterForm';


const Newsletter = (props) => {
  const { pageSection } = props;
  const { newsletterList } = pageSection || {}

  const { page } = useCMSContext();

  return (
    <div className="text-center">
      <div className="w-96 mx-auto">
        <NewsletterForm
          formId={'190711949'}
          // newsletterList={newsletterList}
          source={`${page._type}-${page._id}.${pageSection._type}-${pageSection._key}`}
        />
      </div>
    </div>
  );
};

export default Newsletter;
