import React from 'react';

import StoryPreview from "../../parts/StoryPreview";


const PromotedStories = (props) => {
  const { pageSection } = props;

  return (
    <div className="flex flex-col">
      {pageSection.promotedStories.map((story, i) => (
        <StoryPreview
          key={i}
          story={story}
          imageOnTheRight={i % 2 === 0}
        />
      ))}
    </div>
  );
};

export default PromotedStories;
