import { navigate } from "gatsby-link";
import React, { useCallback, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { useCMSContext } from "@happy-rabbit/gatsby-sanity-cms";
import { Button } from "@happy-rabbit/component-library";

import { useCartStateContext } from "../../contexts/CartContext";
import { useDrawerStateContext } from "../../contexts/DrawerContext";
import CartLineItems from "./CartLineItems";
import CartTotals from "./CartTotals";


const ShoppingCart = (props) => {
  const { cart } = useCartStateContext();
  const { lang, site } = useCMSContext();
  const { close } = useDrawerStateContext();

  const lineItems = useMemo(() => cart?.checkout?.lineItems || [], [cart]);

  const handleCheckoutClick = useCallback(() => {
    try {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push(function() { this.reset(); });
      window.dataLayer.push({
        event: 'eec.checkout',
        ecommerce: {
          currencyCode: cart?.checkout?.currencyCode,
          checkout: {
            actionField: {
              step: 1
            },
            products: lineItems.map(lineItem => ({
              id: lineItem.variant.sku,
              name: `${lineItem.title} / ${lineItem.variant.title}`,
              quantity: lineItem.quantity,
              dimension2: lineItem.variant.selectedOptions[0]?.value || '',
              dimension3: lineItem.variant.selectedOptions[1]?.value || '',
            })),
          },
        },
      });
    } catch(e) {
      console.log(e);
    }

    const prefix =
      site._id === "global"
        ? ""
        : `/${site.slug.current}/${lang}`;
    navigate(`${prefix}/checkout`);
  }, [cart?.checkout, lang, lineItems, site]);

  return (
    <div className="h-full flex flex-col flex-stretch">
      <div className="h-16 flex-initial px-6">
        <h2 className="text-center text-2xl">
          <FormattedMessage
            id="shoppingCart.your-shopping-bag"
            defaultMessage="Your shopping bag"
          />
        </h2>
      </div>
      
      <CartLineItems lineItems={lineItems} />

      <div className="flex-initial flex flex-col">
        <div className="mx-6">
          <CartTotals />
        </div>

        <div className="flex w-full h-12">
          <div className="w-1/2">
            <Button
              disabled={cart?.cartRequest}
              fullWidth={true}
              height={'full'}
              onClick={close}
              theme="ghost"
            >
              <FormattedMessage
                id="shoppingCart.continue-shopping"
                defaultMessage="Continue Shopping"
              />
            </Button>
          </div>
          
          <div className="w-1/2">
            <Button
              disabled={cart?.cartRequest}
              fullWidth={true}
              height={'full'}
              onClick={handleCheckoutClick}
              theme="success"
            >
              <FormattedMessage
                id="shoppingCart.checkout"
                defaultMessage="Checkout"
              />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShoppingCart;
