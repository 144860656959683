import React, { useEffect, useState } from 'react'
import { Widget } from '@typeform/embed-react'
import '@typeform/embed/build/css/widget.css'
import { getResponsiveClasses } from '../../nodes/feeds/utils'

const IframeEmbed = ({ pageSection }) => {
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const checkMobile = () => {
        setIsMobile(window.innerWidth <= 768)
      }

      checkMobile()
      window.addEventListener('resize', checkMobile)

      return () => window.removeEventListener('resize', checkMobile)
    }
  }, [])

  const heightClasses = getResponsiveClasses(pageSection.height).join(' ')
  const widthClasses = getResponsiveClasses(pageSection.width).join(' ')

  if (pageSection.formName && pageSection.formId) {
    return <Widget className={`${heightClasses} ${widthClasses}`} id={pageSection.formId}  />
  }

  const url = pageSection.formId
    ? `https://form.typeform.com/to/${pageSection.formId}`
    : pageSection.defaultCellLayout.current

  return <iframe className={`${heightClasses} ${widthClasses}`} src={url}></iframe>
}
export default IframeEmbed
