import React, {useRef, useEffect, useState} from "react";
import clsx from "clsx";
import { getLocaleValue, useCMSContext } from "@happy-rabbit/gatsby-sanity-cms";
import { FormattedMessage } from "react-intl";
import {setQueryParams, getQueryParams, removeQueryParams} from './urlQueryParams.js'
import Button  from "@happy-rabbit/component-library/lib/Button";

const Checkbox = props => {
  const {selected} = props
  return (
    <div className={clsx(
        'w-5 h-5 rounded border border-depalmaBlack grid place-content-center',
        selected ? 'bg-depalmaBlack' : 'bg-white'
      )}>
      <svg className="w-4 h-4" version="1.1" viewBox="0 0 18 15" xmlns="http://www.w3.org/2000/svg">
        <path d="M6,10.2 L1.8,6 L0.4,7.4 L6,13 L18,1 L16.6,-0.4 L6,10.2 Z" fill="white"/>
      </svg>
    </div>
  );
}

const FilterOptionsBlock = props => {
  const {label, options, selectedOptions, onChange} = props
  const [optionsVisible, showOptions] = useState(true);
return props.hideBlock ? null : (
  <div>
    <div
      onClick={() => showOptions((optionsVisible) => !optionsVisible)}
      className="flex content-center place-content-between px-4 py-4 bg-depalmaWhite cursor-pointer">
      <div>{label}</div>
      <div className={clsx("transform duration-300 ease-in-out", { 'rotate-180': optionsVisible})}>
        <svg className="w-6" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg"><path d="M14.83 16.42l9.17 9.17 9.17-9.17 2.83 2.83-12 12-12-12z"></path><path d="M0-.75h48v48h-48z" fill="none"></path></svg>
      </div>
    </div>
    <div className={clsx({'hidden': !optionsVisible})}>
    {options.map(option =>
      <div key={option.key} onClick={() => onChange(option.value)} className="px-4 py-2 cursor-pointer hover:bg-depalmaWhite">
        <div className="flex items-center"

        >

          <Checkbox selected={selectedOptions.includes(option.value)}/>
          <span className="ml-3 min-w-0 flex-1 text-black cursor-pointer">
             { option.label }
          </span>
        </div>
      </div>
    )}
    </div>
  </div>
)
}

const ProductFilter = props => {

  const { lang } = useCMSContext();
  const ref = useRef()
  const {productPageVariants, onApply} = props

  const [filtersShown, showFilters] = useState(false);
  const [queryLoaded, setLoaded] = useState(false);

  const [selectedCategories, setCategories] = useState([])
  const [selectedSizes, setSizes] = useState([])
  const [selectedColors, setColors] = useState([])
  const [selectedCustomizations, setCustoms] = useState([])


  const customizationOptions = [
    {label: <FormattedMessage id="productView.filters.yesOption" />, value: 'true', key: 'true'}
    // {label: <FormattedMessage id="productView.filters.noOption" />, value: 'false', key: 'false'},
  ]


  const categoryOptions = productPageVariants.reduce((acc, item) => {
    const categoryItem = item.product.productSubCategory
    if (acc.some(j => j.key === categoryItem._id)) return acc
    acc.push({
      key: categoryItem._id,
      label: getLocaleValue(categoryItem.title, lang),
      value: getLocaleValue(categoryItem.title, lang)
    })
    return acc
  }, []).sort((a,b) => {
    const labelA = a.label.toUpperCase()
    const labelB = b.label.toUpperCase()
    if (labelA < labelB) { return -1 }
    if (labelA > labelB) { return 1 }
    return 0
  })

  const sizeOptions = productPageVariants.reduce((acc, item) => {
    item.product.productSizes.forEach(sizeObject => {
      if (acc.some(j => j.key === sizeObject._id)) return acc
      acc.push({
        key: sizeObject._id,
        label: sizeObject.abbreviation,
        value: sizeObject.abbreviation
      })
    });
    return acc
  }, [])

  const colorOptions = productPageVariants.reduce((acc, item) => {
    const colorItem = item.productColorway
    if (acc.some(j => j.key === colorItem._id)) return acc
    acc.push({
      key: colorItem._id,
      label: getLocaleValue(colorItem.name, lang),
      value: getLocaleValue(colorItem.name, lang)
    })
    return acc
  }, []).sort((a,b) => {
    const labelA = a.label.toUpperCase()
    const labelB = b.label.toUpperCase()
    if (labelA < labelB) { return -1 }
    if (labelA > labelB) { return 1 }
    return 0
  })

  function updateSelectedOptionsArray (array, option) {
    if (array.includes(option)) {
      array = array.filter(i => i !== option)
    } else {
      array = [...array, option]
    }
    return array
  }

  function handleSizesChoice (option) {
    setSizes((selectedSizes) => updateSelectedOptionsArray(selectedSizes, option))
  }

  function handleColorChoice (option) {
    setColors((selectedColors) => updateSelectedOptionsArray(selectedColors, option))
  }

  function handleCustomsChoice (option) {
    setCustoms(selectedCustomizations => updateSelectedOptionsArray(selectedCustomizations, option))
  }

  function handleCategoryChoice (option) {
    setCategories(selectedCategories => updateSelectedOptionsArray(selectedCategories, option))
  }

  function onApplyClick () {
    applyFilters()
    showFilters(false)
  }

  function applyFilters () {
    const filteredProducts = productPageVariants
      .filter(i => {
        return selectedCategories.length
        ? selectedCategories.includes(getLocaleValue(i.product.productSubCategory.title, lang))
        : i
      })
      .filter(i => {
        return (selectedSizes.length)
        ? i.product.productSizes.some(s => selectedSizes.includes(s.abbreviation))
        : i
      })
      .filter(i => {
        return selectedColors.length
        ? selectedColors.includes(getLocaleValue(i.productColorway.name, lang))
        : i
      })
      .filter(i => {
        return selectedCustomizations.length
        ? selectedCustomizations.includes(`${!!i.product.profilingFriendly}`)
        : i
      })
    updateSearchQuery()
    onApply(filteredProducts)

  }
  function resetFilters () {
    setCategories([])
    setColors([])
    setSizes([])
    setCustoms([])
    removeQueryParams('sizes')
    removeQueryParams('colors')
    removeQueryParams('customization')
    onApply(productPageVariants)
    showFilters(false)
  }

  function updateSearchQuery () {
    if (selectedCategories.length) {
      setQueryParams([{'category': selectedCategories}])
    } else {
      removeQueryParams('category')
    }
    if (selectedSizes.length) {
      setQueryParams([{'sizes': selectedSizes}])
    } else {
      removeQueryParams('sizes')
    }
    if (selectedColors.length) {
      setQueryParams([{'colors': selectedColors}])
    } else {
      removeQueryParams('colors')
    }
    if (selectedCustomizations.length) {
      setQueryParams([{'customization': selectedCustomizations}])
    } else {
      removeQueryParams('customization')
    }
  }

  useEffect(() => {
    const categoryFromUrl = getQueryParams('category')
    const sizesFromUrl = getQueryParams('sizes')
    const colorsFromUrl = getQueryParams('colors')
    const customizationFromUrl = getQueryParams('customization')
    if (categoryFromUrl.length || sizesFromUrl.length || colorsFromUrl.length || customizationFromUrl.length) {
      setCategories(categoryFromUrl)
      setSizes(sizesFromUrl)
      setColors(colorsFromUrl)
      setCustoms(customizationFromUrl)
      setLoaded(true)
    }
  }, [])

  useEffect(() => {
    applyFilters()
  } ,[queryLoaded, selectedCategories, selectedSizes ,selectedColors, selectedCustomizations])


  useEffect(() => {
    const checkIfClickedOutside = e => {
      if (filtersShown && ref.current && !ref.current.contains(e.target)) {
        showFilters(false)
      }
    }
    document.addEventListener("mousedown", checkIfClickedOutside)
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside)
    }
  }, [filtersShown])

  return (
    <div ref={ref} className="text-sm">
        <button onClick={() => showFilters((filtersShown) => !filtersShown)} className="flex content-center items-center cursor-pointer outline-none focus:outline-none">
          <div className="pr-2">
            <svg className="w-6" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
              <g>
                <path d="M28,9H11a1,1,0,0,1,0-2H28a1,1,0,0,1,0,2Z"/>
                <path d="M7,9H4A1,1,0,0,1,4,7H7A1,1,0,0,1,7,9Z"/>
                <path d="M21,17H4a1,1,0,0,1,0-2H21a1,1,0,0,1,0,2Z"/>
                <path d="M11,25H4a1,1,0,0,1,0-2h7a1,1,0,0,1,0,2Z"/>
                <path d="M9,11a3,3,0,1,1,3-3A3,3,0,0,1,9,11ZM9,7a1,1,0,1,0,1,1A1,1,0,0,0,9,7Z"/>
                <path d="M23,19a3,3,0,1,1,3-3A3,3,0,0,1,23,19Zm0-4a1,1,0,1,0,1,1A1,1,0,0,0,23,15Z"/>
                <path d="M13,27a3,3,0,1,1,3-3A3,3,0,0,1,13,27Zm0-4a1,1,0,1,0,1,1A1,1,0,0,0,13,23Z"/>
                <path d="M28,17H25a1,1,0,0,1,0-2h3a1,1,0,0,1,0,2Z"/>
                <path d="M28,25H15a1,1,0,0,1,0-2H28a1,1,0,0,1,0,2Z"/>
                </g>
            </svg>
          </div>
          <span><FormattedMessage id="productView.filters.title" /></span>
        </button>

        <div className={clsx({'hidden': !filtersShown}, 'absolute z-10 sticky-filters-dropdown overflow-auto overscroll-contain bg-white w-80 top-left max-h-full  mt-4 shadow-xl border border-depalmaWhite')}>
          <div>
            <FilterOptionsBlock
              hideBlock={categoryOptions.length < 2}
              label={<FormattedMessage id="productView.category" />}
              options={categoryOptions}
              selectedOptions={selectedCategories}
              onChange={(payload) => handleCategoryChoice(payload)}
            />
            <FilterOptionsBlock
              label={<FormattedMessage id="productView.size" />}
              options={sizeOptions}
              selectedOptions={selectedSizes}
              onChange={(payload) => handleSizesChoice(payload)}
            />
            <FilterOptionsBlock
              label={<FormattedMessage id="productView.color" />}
              options={colorOptions}
              selectedOptions={selectedColors}
              onChange={(payload) => handleColorChoice(payload)}
            />
            <FilterOptionsBlock
              label={<FormattedMessage id="productView.customizable" />}
              options={customizationOptions}
              selectedOptions={selectedCustomizations}
              onChange={(payload) => handleCustomsChoice(payload)}
            />
          </div>
          {/* BURRONS */}
          <div className="sticky bottom-0 left-0">
            <div className="flex content-center place-content-between border-t border-black px-4 py-4 bg-depalmaWhite">
            <Button
              className="w-full"
              onClick={() => onApplyClick()}
              type="button"
              theme="black"
            >
              <FormattedMessage id="productView.apply" />
            </Button>
            <Button
              className="w-full ml-4"
              onClick={() => resetFilters()}
              type="button"
              theme="black"
            >
              <FormattedMessage id="productView.clear" />
            </Button>
            </div>
          </div>

        </div>

    </div>
  );
};

export default ProductFilter;