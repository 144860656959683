const DEFAULT_LANG = 'en';

function getLocaleValue(localeObj, lang) {
  if (localeObj) {
    return localeObj[lang] ? localeObj[lang] : localeObj[DEFAULT_LANG];
  }

  return null;
}

function idOrRef(obj) {
  return (obj && obj._id) || (obj && obj._ref);
}

function scopeMatchFunc(limitBy) {
  return item => {
    // Group the productScope by type
    const idsByType = (item.productScope || []).reduce(
      (acc, { _type, ...scope }) => ({
        ...acc,
        [_type]: acc[_type]
          ? [...acc[_type], idOrRef(scope)]
          : [idOrRef(scope)],
      }),
      {}
    );

    // Returns true if at least one scope for each type, was also present in +limitBy+
    return Object.values(idsByType).every(ids => {
      return limitBy && limitBy.find && limitBy.find(s => ids.indexOf(idOrRef(s)) > -1);
    });
  };
}

function getAllScoped(selectFrom, limitBy) {
  if (selectFrom && selectFrom.filter) {
    return selectFrom.filter(scopeMatchFunc(limitBy));
  }
  return [];
}

function useBestMatchProductImage(
  productImages,
  matchFields,
  notOneOf = null
) {
  const images = productImages.filter(
    i => !notOneOf || notOneOf.indexOf(i) === -1
  );
  if (images.length === 0) return null;

  return images.find(i =>
    Object.keys(matchFields).every(f => i[f] == matchFields[f])
  );
}

function getResponsiveClasses(responsiveObject) {
  if (!responsiveObject){
    return []
  }
  return Object.entries(responsiveObject).reduce((acc, [key,value]) => {
    if (value && ['xs', 'sm', 'md', 'lg', 'xl'].includes(key)) {
      return [...acc,`${key}:${value}`]
    }
    return acc
  },[])
}

exports.getLocaleValue = getLocaleValue;
exports.idOrRef = idOrRef;
exports.scopeMatchFunc = scopeMatchFunc;
exports.getAllScoped = getAllScoped;
exports.useBestMatchProductImage = useBestMatchProductImage;
exports.getResponsiveClasses = getResponsiveClasses;
