import React, { useCallback } from "react";

import { PageLink } from "@happy-rabbit/gatsby-sanity-cms/components";

const itemMap = {
  "navItems.page": PageLink,
};

const Navigation = props => {
  const {
    lang,
    nav,
    site,
  } = props;

  const renderMenuItem = useCallback(navItem => {
    const Comp = itemMap[navItem._type];

    if (!Comp)
      return null;

    return (
      <Comp
        className="p-4 pl-0 inline-block transition duration-300"
        key={navItem._key}
        lang={lang}
        pageConfig={navItem.navItemPage?.pageConfig}
        site={site}
        title={navItem.title[lang]}
      />
    );
  }, [lang, site]);

  return nav.navItems.map(renderMenuItem)
};

export default Navigation;
