import React, { useMemo, useState } from "react";

import { withNavs } from "../../queries";

import HeaderBar from "./HeaderBar";
import Menu from "./Menu";
import useWindowScrollSticky from "./useWindowScrollSticky";

import "./index.css";
import clsx from "clsx";
import { useIdentityStateContext } from "../../contexts/IdentityContext";
import SignInUp from "../SignInUp";
import { useCMSContext } from "@happy-rabbit/gatsby-sanity-cms";


const Header = props => {
  const {
    allSanityNav: { edges: navEdges = [] },
  } = props;

  const { lang, site, page } = useCMSContext();
  const { user, logout } = useIdentityStateContext();

  const headerTheme = page?.pageDesign?.headerTheme;

  const navsById = useMemo(() => navEdges.reduce((acc, { node: nav }) => ({ ...acc, [nav.navId]: nav }), {}), [navEdges]);
  const headerNav = navsById["header-main"];

  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [signInUp, setSignInUp] = useState(null);

  useWindowScrollSticky(32);

  return (
    <header className={clsx("sticky-header left-0 right-0 px-6 flex flex-col pin-t pin-r pin-l text-depalmaBlack bg-white z-50", { "menu-is-open": menuIsOpen, 'theme-white': headerTheme === 'white' })}>
      <div className="">
        <HeaderBar
          lang={lang}
          logout={logout}
          menuIsOpen={menuIsOpen}
          nav={headerNav}
          page={page}
          setMenuIsOpen={setMenuIsOpen}
          setSignInUp={setSignInUp}
          site={site}
          user={user}
        />

        <Menu isOpen={menuIsOpen} nav={headerNav} />
      </div>

      {signInUp ? (
        <SignInUp onClose={() => setSignInUp(null)} defaultTab={signInUp} />
      ) : null}
    </header>
  );
};

export default withNavs(Header);
