import clsx from "clsx";
import React from "react";
import { FormattedMessage } from "react-intl";
import { defaultClassName as buttonClassName, THEMES } from "@happy-rabbit/component-library/lib/Button";
import { useCMSContext } from "@happy-rabbit/gatsby-sanity-cms";

import { useCountryStateContext } from "../../contexts/CountryContext";

const NewsletterForm = props => {
  const {
    hideTitle = false,
    formId,
    source,
  } = props;

  const { country, behaviour } = useCountryStateContext();
  const { lang } = useCMSContext();

  return (
    <div>
      <form
        action={`https://www.getdrip.com/forms/${formId}/submissions`}
        data-drip-embedded-form={formId}
        method="post"
      >
        <input
          type="hidden"
          name="fields[newsletter_source]"
          value={source}
        />

        <input
          type="hidden"
          name="fields[lang]"
          value={lang}
        />

        {country ? (
          <input
            type="hidden"
            name="fields[country_code]"
            value={country.slug?.current}
          />
        ) : null}

        {Object.keys(behaviour || {}).map(k => (
          <input
            key={k}
            type="hidden"
            name={`fields[bhvr__${k}]`}
            value={behaviour[k]}
          />
        ))}

        {!hideTitle ? (
          <h2 className="mb-4 averta-bold">
            <FormattedMessage
              id="footer.signup-for-our-newsletter"
              defaultMessage="Signup for our Newsletter"
            />
          </h2>
        ) : null}

        {/* <p className="hidden">
          <label>Don’t fill this out if you’re human: <input name="name" /></label>
        </p> */}

        <div className="flex">
          <input
            className="py-1 px-4 w-2/3 outline-none text-depalmaBlack"
            placeholder="Email"
            type="email"
            name="fields[email]"
            required
          />
          <input
            className={clsx(buttonClassName, THEMES['black'])}
            type="submit"
            value="Signup"
          />
        </div>
      </form>
    </div>
  );
};

export default NewsletterForm;
