import React from "react";
import clsx from "clsx";

import { useCartStateContext } from "../../contexts/CartContext";
import { useDrawerStateContext } from "../../contexts/DrawerContext";

import BagBlack from "../../images/bag_black.svg";
import BagWhite from "../../images/bag_white.svg";

const ShoppingCartButton = props => {
  const { headerTheme, menuIsOpen } = props;
  const { cart } = useCartStateContext();
  const { openRight } = useDrawerStateContext();

  const numberOfItems = cart?.checkout?.lineItems?.length || 0;
  const renderIndicator = count => (
    <span
      className="absolute bg-royalBlue rounded-full block text-xs text-center text-depalmaWhite w-4 h-4 leading-tight"
      style={{ top: "-2px", right: "-8px" }}
    >
      {count}
    </span>
  );

  return (
    <div className="relative">
      <button className="focus:outline-none" onClick={openRight}>
        <img src={BagBlack} alt={'Bag'} className={clsx("h-6", headerTheme === 'white' && !menuIsOpen ? "show-on-sticky hidden" : '')} />
        <img src={BagWhite} alt={'Bag'} className={clsx("h-6", headerTheme === 'white' && !menuIsOpen ? "hide-on-sticky" : "hidden")} />

        {numberOfItems > 0 ? renderIndicator(numberOfItems) : null}
      </button>
    </div>
  );
};

export default ShoppingCartButton;
