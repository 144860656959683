import React from "react";

const SizeGuideTable = props => {
  const { sizeGuide } = props;
  const {
    measurements,
    title,
    unitOfMeasure,
  } = sizeGuide || {};
  const {
    noOfCols,
    noOfRows,
    cornerLabel,
    rowLabels,
    colLabels,
  } = measurements || {};

  if (!measurements)
    return null;

  return (
    <div className={`grid grid-cols-${noOfCols+1} px-6 sm:px-0`}>
      {[...Array(noOfRows+1)].map((e,rowNo) => [...Array(noOfCols+1)].map((f, colNo) => {
        if (rowNo === 0 && colNo === 0) {
          return <div key={rowNo * (noOfCols+1) + colNo} className="averta-bold">{cornerLabel}</div>;
        } else if (rowNo === 0) {
          return <div key={rowNo * (noOfCols+1) + colNo} className="averta-bold text-right">{colLabels[colNo-1]}</div>;
        } else if (colNo === 0) {
          return <div key={rowNo * (noOfCols+1) + colNo} className="averta-bold">{rowLabels[rowNo-1]}</div>;
        } else {
          const row = `tableContent${rowNo-1}`;
          return <div key={rowNo * (noOfCols+1) + colNo} className="text-right">{measurements[row] && measurements[row][colNo-1]}</div>;
        }
      }))}
    </div>
  );
}

export default SizeGuideTable;
