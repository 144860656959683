import Newsletter from './Newsletter'
import ProductByCategoryIndex from './ProductByCategoryIndex'
import ProductDescriptionsAndCare from './ProductDescriptionsAndCare'
import ProductIndex from './ProductIndex'
import PromotedStories from './PromotedStories'
import SizeGuide from './SizeGuide'
import StoryIndex from './StoryIndex'
import Typeform from './Typeform'
import IframeEmbed from './IframeEmbed'

const components = {
  'pageSections.newsletter': Newsletter,
  'pageSections.productByCategoryIndex': ProductByCategoryIndex,
  'pageSections.productDescriptionsAndCare': ProductDescriptionsAndCare,
  'pageSections.productIndex': ProductIndex,
  'pageSections.promotedStories': PromotedStories,
  'pageSections.storyIndex': StoryIndex,
  'pageSections.sizeGuide': SizeGuide,
  'pageSections.typeform': Typeform,
  'pageSections.iframeEmbed': IframeEmbed,
}

export function resolvePageSection(pageSection) {
  return components[pageSection._type]
}
