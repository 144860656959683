import React from 'react'
import { FormattedMessage } from 'react-intl'
import { PageSections } from '@happy-rabbit/gatsby-sanity-cms'
import { resolvePageSection } from '../PageSections'
import Modal from '../../components/Modal'
import { copiedFromBrowserLog } from './mock-page'

const page = { pageSections: [copiedFromBrowserLog] }

const SignInUp = ({ onClose }) => {
  return (
    <Modal
      hasConfirm={false}
      hasClose={false}
      hideHeader={true}
      onClose={onClose}
      title={'Sign In / Sign Up'}
    >
      <div className="flex flex-col items-center font-bold text-2xl text-center pr-8 pl-8">
        <span>
          <FormattedMessage id="signInUp.heading.p1" defaultMessage="Sign Up" />
        </span>
        <span>
          <FormattedMessage id="signInUp.heading.p2" defaultMessage="Sign Up" />
        </span>
      </div>
      <div className="pt-8">
        <div className={''}>
          <PageSections
            page={page}
            pageSections={page.pageSections}
            resolvePageSection={resolvePageSection}
          />
        </div>
      </div>
    </Modal>
  )
}

export default SignInUp
