export function setQueryParams (array) {
  const url = new URL(window.location.href);
  array.forEach((i) => {
    url.searchParams.set(Object.keys(i)[0], Object.values(i)[0]);
  });
  window.history.replaceState(null, null, url.toString());
}

export function getQueryParams (key) {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  return params[key] && params[key].length ? params[key].split(',') : [];
}

export function removeQueryParams (key) {
  const url = new URL(window.location.href);
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  if (params[key] && params[key].length) {
    urlSearchParams.delete(key)
    url.search = urlSearchParams.toString()
    window.history.replaceState(null, null, url.toString());
  }
}