import React, { useCallback, useRef } from "react";
import { LocaleString, useCMSContext } from "@happy-rabbit/gatsby-sanity-cms";
import { FormattedMessage } from "react-intl";

import * as careSymbols from "../../images/care";
import { useProductDescriptionsAndCareInstructions } from "../../utils/productScope";

const SYMBOL_CATEGORIES = [
  'washing',
  'bleaching',
  'drying',
  'tumbleDrying',
  'ironing',
  'professional',
];

const ProductDescriptions = props => {
  const {
    productDescriptionSet,
  } = props;

  const renderItem = useCallback((item, i) => (
    <li className="mb-2 list-disc ml-6" key={i}>
      <LocaleString value={item.description} />
    </li>
  ));

  return (
    <div className="px-8">
      <h3 className="mb-4 text-xl md:text-4xl">
        <FormattedMessage
          id="productView.product-descriptions"
          defaultMessage="Product Descriptions"
        />
      </h3>
      
      <ul>
        {productDescriptionSet.productDescriptionItems.map(renderItem)}
      </ul>
    </div>
  );
};

const CareInstructions = props => {
  const {
    careInstructionSet,
  } = props;

  const renderItem = useCallback((item, i) => (
    <li className="mb-2 list-disc ml-6" key={i}>
      <LocaleString value={item.title} />
    </li>
  ));

  const renderSymbol = useCallback((category, i) => {
    const symbols = careSymbols[category];
    const instruction = careInstructionSet[category];

    if (instruction && symbols && symbols[instruction]) {
      return (
        <li className="flex items-center" key={i}>
          <div className="w-12 flex flex-col items-center">
            <img
              alt={`${category}_${instruction}`}
              className="h-12"
              src={symbols[instruction]}
            />
          </div>
          <p>
            <FormattedMessage
              id={`productView.careInstructions.${category}.${instruction}`}
              defaultMessage=""
            />
          </p>
        </li>
      );
    }
    return null;
  }, [careInstructionSet]);

  return (
    <div className="px-8">
      <h3 className="mb-4 text-xl md:text-4xl">
        <FormattedMessage
          id="productView.care-instructions"
          defaultMessage="Care Instructions"
        />
      </h3>

      <ul>
        {SYMBOL_CATEGORIES.map(renderSymbol)}
        {careInstructionSet.extraInstructions.map(renderItem)}
      </ul>
    </div>
  );
};

const ProductDescriptionsAndCare = props => {
  const { pageSection } = props;
  const { inclDescriptions, inclCare } = pageSection;

  const { page: productPageVariant, pageRefs } = useCMSContext();
  const { product, productColorway } = productPageVariant;

  const {
    scopedCareInstructionSet,
    scopedProductDescriptionSet,
  } = useProductDescriptionsAndCareInstructions(product, productColorway);

  const productDescriptionsAndCareRef = pageRefs.current.productDescriptionsAndCareRef = useRef(null);

  return (
    <div className="grid md:grid-cols-2 gap-4 mb-8" ref={productDescriptionsAndCareRef}>
      {inclDescriptions && scopedProductDescriptionSet ? <ProductDescriptions productDescriptionSet={scopedProductDescriptionSet} /> : null}
      {inclCare && scopedCareInstructionSet ? <CareInstructions careInstructionSet={scopedCareInstructionSet} /> : null}
    </div>
  );
};

export default ProductDescriptionsAndCare;
