import { useEffect } from "react";

const useWindowScrollSticky = (scrollY) => {
  useEffect(() => {
    const handleScroll = () => {
      document.documentElement.dataset.sticky = window.scrollY > scrollY;
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
};

export default useWindowScrollSticky;
