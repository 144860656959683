import React, { createContext, useCallback, useContext, useState } from "react";

const DrawerStateContext = createContext(null);

const DrawerProvider = props => {
  const { children } = props;

  const [drawerOpen, setDrawerOpen] = useState(null);

  const close = useCallback(() => {
    setDrawerOpen(null);
    if (window?.zE?.show) {
      window.zE.show();
    }
  }, [setDrawerOpen]);
  const openLeft = useCallback(() => {
    setDrawerOpen('left');
    if (window?.zE?.hide) {
      window.zE.hide();
    }
  }, [setDrawerOpen]);
  const openRight = useCallback(() => {
    setDrawerOpen('right');
    if (window?.zE?.hide) {
      window.zE.hide();
    }
  }, [setDrawerOpen]);

  const context = {
    close,
    drawerOpen,
    openLeft,
    openRight,
  }
  
  return (
    <DrawerStateContext.Provider value={context}>
      {children}
    </DrawerStateContext.Provider>
  );
};

export const useDrawerStateContext = () => {
  const context = useContext(DrawerStateContext);

  if (context === undefined) {
    throw new Error('useDrawerStateContext must be used within a DrawerProvider');
  }

  return context;
};

export default DrawerProvider;
