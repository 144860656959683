import React from "react";
import { ResponsiveImage } from "@happy-rabbit/gatsby-sanity-cms/components";
import clsx from "clsx";
import { getLocaleValue, useCMSContext } from "@happy-rabbit/gatsby-sanity-cms";

const Photo = props => {
  const {
    children,
    mainPhoto,
    noOfColsArr = [2],
    secondaryPhoto,
  } = props;

  const { lang } = useCMSContext();

  return (
    <div className="h-0 pt-3:4 relative overflow-hidden">
      <div className="absolute w-full h-full left-0 top-0 group">
        {mainPhoto ? (
          <ResponsiveImage
            alt={getLocaleValue(mainPhoto.altText, lang)}
            boxRatio={{xs: '3:4'}}
            className={clsx("w-full h-full", { "group-hover:opacity-0": secondaryPhoto })}
            containerClassName="absolute top-0 left-0 w-full overflow-hidden"
            image={mainPhoto.image}
            noOfColsArr={noOfColsArr}
          />
        ) : null}

        {secondaryPhoto ? (
          <ResponsiveImage
            alt={getLocaleValue(secondaryPhoto.altText, lang)}
            boxRatio={{xs: '3:4'}}
            className="w-full h-full opacity-0 group-hover:opacity-100"
            containerClassName="absolute top-0 left-0 w-full overflow-hidden"
            image={secondaryPhoto.image}
            noOfColsArr={noOfColsArr}
          />
        ) : null}

        {children}
      </div>
    </div>
  );
};

export default Photo;
