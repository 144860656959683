import React, { useCallback, useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { FaSignOutAlt, FaCog } from "react-icons/fa";

import { PageLink } from "@happy-rabbit/gatsby-sanity-cms/components";
import eventStacker from "../../utils/eventStacker";
import { Button } from "@happy-rabbit/component-library";

import UserBlack from "../../images/user_black.svg";
import UserWhite from "../../images/user_white.svg";
import { FormattedMessage } from "react-intl";


const AccountMenu = props => {
  const { logout, onClose, user } = props;

  const ref = useRef();

  useEffect(() => {
    const eventStackId = eventStacker.addListenable({
      // Closes on click outside of the Modal frame
      click: (e) => ref.current && !ref.current.contains(e.target) && onClose(),

      // Closes on Esc key being pressed
      keydown: (e) => e.keyCode === 27 && onClose(),
    });

    return () => eventStacker.removeListenable(eventStackId);
  }, [onClose, ref]);

  const handleLogoutClick = () => logout().then(onClose);

  if (!user)
    return null;

  return (
    <div className="fixed lg:absolute right-0 pr-1 md:pr-0 md:right-auto lg:right-0 left-0 pl-1 md:pl-20 lg:pl-0 lg:left-auto">
      <div className="shadow-lg bg-white text-depalmaBlack text-sm w-full md:w-auto text-left" ref={ref}>
        <div className="py-2 pl-8 pr-4 relative">
          <img src={UserBlack} alt={'User'} className={clsx("h-4 absolute left-0 pl-2")} />
          {user.email}
        </div>
        <PageLink
          className="block py-2 pl-8 pr-4 hover:bg-gray-200 relative"
          pageConfig={{slug: { current: '/account' }}}
        >
          <FaCog className="absolute left-0 pl-2 text-xl" />
          <FormattedMessage
            id="accountMenu.account-settings"
            defaultMessage="Account Settings"
          />
        </PageLink>
        <Button
          className="block w-full text-left py-2 pl-8 pr-4 hover:bg-gray-200 relative"
          theme="none"
          onClick={handleLogoutClick}
        >
          <FaSignOutAlt className="absolute left-0 pl-2 text-xl" />
          <FormattedMessage
            id="accountMenu.logout"
            defaultMessage="Logout"
          />
        </Button>
      </div>
    </div>
  );
}

const AccountButton = props => {
  const { headerTheme, logout, menuIsOpen, setSignInUp, user } = props;
  const [accountOpen, setAccountOpen] = useState(false);

  const handleClose = useCallback(() => {
    setAccountOpen(false);
  }, []);

  const handleClick = useCallback(() => {
    if (user) {
      setAccountOpen(true);
    } else {
      setSignInUp('signUp');
    }
  }, [user])

  return (
    <div className="relative">
      <Button className="focus:outline-none" onClick={handleClick} theme="none" height={null}>
        {user ? (
          <span className="inline-flex h-6 w-6 mb-2 rounded-full justify-center leading-6 bg-depalmaBlue text-depalmaWhite text-sm">
            {user.email[0].toUpperCase()}
          </span>
        ) : (
          <>
            <img src={UserBlack} alt={'User'} className={clsx("h-6", headerTheme === 'white' && !menuIsOpen ? "show-on-sticky hidden" : '')} />
            <img src={UserWhite} alt={'User'} className={clsx("h-6", headerTheme === 'white' && !menuIsOpen ? "hide-on-sticky" : "hidden")} />
          </>
        )}
      </Button>

      {accountOpen ? (
        <AccountMenu logout={logout} onClose={handleClose} user={user} />
      ) : null}
    </div>
  );
}

export default AccountButton;
