import React, {useRef, useState, useEffect} from "react";
import { useCMSContext } from "@happy-rabbit/gatsby-sanity-cms";
import { idOrRef } from "../../utils/productScope";
import clsx from "clsx";
import { FormattedMessage } from "react-intl";
import {setQueryParams, getQueryParams} from './urlQueryParams.js'

const ProductSorting = props => {
  const ref = useRef()
  const {productPageVariants, onApply} = props
  const {site} = useCMSContext();
  const defaultCurrencyCode = site.defaultCurrencyCode
  const sortingOptions = [
    {key: '_mp', value: 'default', label: <FormattedMessage id="productView.sorting.default" />},
    {key: '_new', value: 'createdDesc', label: <FormattedMessage id="productView.sorting.new" /> },
    {key: '_lth', value: 'priceAsc', label: <FormattedMessage id="productView.sorting.priceAsc" /> },
    {key: '_htl', value: 'priceDesc', label: <FormattedMessage id="productView.sorting.priceDesc" /> }
  ]

  const [sortingShown, showSorting] = useState(false);
  const [selectedSorting, setSelectedSorting] = useState(sortingOptions[0]);


  function toggleSortingView () {
    return showSorting((sortingShown) => !sortingShown)
  }

  function onOptionClick (option) {
    setSelectedSorting(option)
    setQueryParams([{'sorting': option.value}])
    toggleSortingView()
  }
  function sortProducts () {
    let sortedProducts = productPageVariants
    const selectedSortingOrder = selectedSorting.value

    const currentProductPrice = (payload) => {
      if (payload.product.productVariants.some(v => v.inventoryStatus !== 'out-of-stock' && payload.productColorway._id === v.productColorway._id)) {
        return payload.product.productVariants.filter(v => v.inventoryStatus !== 'out-of-stock')[0].price[defaultCurrencyCode.toLowerCase()].amount
      } else {
        return payload.product.productVariants[0].price[defaultCurrencyCode.toLowerCase()].amount
      }
    }

    if (selectedSortingOrder === 'priceAsc') {
      sortedProducts = productPageVariants.sort((a,b) => {
        return currentProductPrice(a) - currentProductPrice(b)
      })
    }
    if (selectedSortingOrder === 'priceDesc') {
      sortedProducts = productPageVariants.sort((a,b) => {
        return currentProductPrice(b) - currentProductPrice(a)
      })
    }
    if (selectedSortingOrder === 'default') {
      sortedProducts = productPageVariants.slice().sort((a,b) => {
        const { isAvailable: aIsAvailable, product: aProduct, productColorway: aProductColorway } = a;
        const { isAvailable: bIsAvailable, product: bProduct, productColorway: bProductColorway } = b;

        const aAllOutOfStock = !aIsAvailable
        const bAllOutOfStock = !bIsAvailable

        // console.log(aProduct, aAllOutOfStock)
        // console.log(bProduct, bAllOutOfStock)

        // Uses same method as useProductScope to calculate allOutOfStock, but cannot use
        // a hook inside a dynamic filter since the length of array could potentially change.

        // const aPossibleProductVariants = aProduct.productVariants.filter(
        //   v => idOrRef(v.productColorway) === idOrRef(aProductColorway)
        // );

        // const bPossibleProductVariants = bProduct.productVariants.filter(
        //   v => idOrRef(v.productColorway) === idOrRef(bProductColorway)
        // );

        // const aAllOutOfStock = aPossibleProductVariants.every(
        //   productVariant =>
        //     (productVariant.inventoryStatus || "out-of-stock") === "out-of-stock"
        // );

        // const bAllOutOfStock = bPossibleProductVariants.every(
        //   productVariant =>
        //     (productVariant.inventoryStatus || "out-of-stock") === "out-of-stock"
        // );

        if (aAllOutOfStock !== bAllOutOfStock) {
          return aAllOutOfStock ? 1 : -1;

        } else if (aProduct.code === bProduct.code) {
          return aProductColorway.code == bProductColorway.code ? 0 : aProductColorway.code > bProductColorway.code ? 1 : -1;

        } else {
          return aProduct.code == bProduct.code ? 0 : aProduct.code > bProduct.code ? 1 : -1;
        }
      })
    }
    if (selectedSortingOrder === 'createdDesc') {
      sortedProducts = productPageVariants.slice().sort((a,b) => {
        return new Date(b.product.created_at) - new Date(a.product.created_at);
      })
    }
    onApply([...sortedProducts])
  }

  useEffect(() => {
    const sortingValue = getQueryParams('sorting').join() || sortingOptions[0].value
    setSelectedSorting(sortingOptions.find(s => s.value === sortingValue))
  }, [])

  useEffect(() => sortProducts(), [productPageVariants, selectedSorting])

  useEffect(() => {
    const checkIfClickedOutside = e => {
      if (sortingShown && ref.current && !ref.current.contains(e.target)) {
        showSorting(false)
      }
    }
    document.addEventListener("mousedown", checkIfClickedOutside)

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside)
    }
  }, [sortingShown])

  return (
    <div ref={ref} className="text-sm">
      <button onClick={() => toggleSortingView()} className="flex content-center items-center cursor-pointer outline-none focus:outline-none">
        <span className="pr-1"> <FormattedMessage id="productView.sorting.sort" /> - {selectedSorting.label}</span>
        <div className={clsx("transform duration-300 ease-in-out", { 'rotate-180': sortingShown})}>
          <svg className="w-4" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.83 16.42l9.17 9.17 9.17-9.17 2.83 2.83-12 12-12-12z"/>
            <path d="M0-.75h48v48h-48z" fill="none"/>
          </svg>

        </div>
      </button>
      <div className={clsx({'hidden': !sortingShown}, 'absolute z-10 sticky-sorting-dropdown overflow-auto overscroll-contain bg-white w-56 right-0 max-h-full  mt-4 shadow-xl border border-depalmaWhite')}>
          {sortingOptions.map(option =>

            <div
              onClick={() => onOptionClick(option)} key={option.key}
              className={clsx({'bg-depalmaWhite': selectedSorting.key === option.key}, 'flex content-center px-4 py-2 bg-white cursor-pointer hover:bg-depalmaWhite')}
            >
              {option.label}
            </div>

          )}
      </div>
    </div>
  )
}
export default ProductSorting