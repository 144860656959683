import React from "react";
import { FaCcVisa, FaCcMastercard, FaCcAmex, FaCcPaypal, FaCcApplePay, FaBtc, FaEthereum } from "react-icons/fa";

import iconDogecoin from "../../images/dogecoin_icon.svg";

const PaymentOptions = props => {
  const { paymentMethods } = props;
  const {
    amex,
    applePay,
    bitcoin,
    coinbase,
    dogecoin,
    ethereum,
    googlePay,
    invoiceForCompanies,
    klarnaPayLater,
    klarnaPayNow,
    klarnaSliceIt,
    maestro,
    mastercard,
    payPal,
    visa,
   } = paymentMethods || {};

  return (
    <>
      {visa ? <FaCcVisa /> : null}
      {mastercard ? <FaCcMastercard /> : null}
      {amex ? <FaCcAmex /> : null}
      {klarnaPayNow ? <img src="https://x.klarnacdn.net/payment-method/assets/badges/generic/white/klarna.svg" alt="Klarna" className="h-6" /> : null}
      {payPal ? <FaCcPaypal /> : null}
      {applePay ? <FaCcApplePay /> : null}
      {bitcoin ? <FaBtc /> : null}
      {ethereum ? <FaEthereum /> : null}
      {dogecoin ? <img src={dogecoin} alt="Dogecoin" className="h-6" /> : null}
    </>
  );
};

export default PaymentOptions;
