import React, { useCallback } from "react";

import { PageLink } from "@happy-rabbit/gatsby-sanity-cms/components";

const itemMap = {
  "navItems.page": PageLink,
};

const Menu = props => {
  const {
    lang,
    nav,
    site,
  } = props;

  const renderMenuItem = useCallback(navItem => {
    const Comp = itemMap[navItem._type];

    if (!Comp)
      return null;

    return (
      <div key={navItem._key} className="mb-2 block">
        <Comp
          lang={lang}
          pageConfig={navItem.navItemPage?.pageConfig}
          site={site}
          title={navItem.title[lang]}
        />
      </div>
    );
  }, [lang, site]);

  return (
    <div className="">
      <h2 className="mb-4 averta-bold">{nav.title[lang]}</h2>

      {nav.navItems.map(renderMenuItem)}
    </div>
  );
};

export default Menu;
