import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { FormattedMessage } from "react-intl";

import ProductGrid from "../ProductGrid";
import ProductFilter from "../ProductGridModifiers/ProductFilter";
import ProductSorting from "../ProductGridModifiers/ProductSorting";
import { getCategoryVariantStatus } from "../../utils/shopifyInventoryStatus";
import { useCartStateContext } from "../../contexts/CartContext";

const ModifiedProductGrid = props => {
  const { productPageVariants } = props;

  const [availableProducts, setAvailableProducts] = useState(productPageVariants);
  const [queriedProducts, setQueriedProducts] = useState(availableProducts);
  const [sortedProducts, setSortedProducts] = useState(queriedProducts);

  const { client } = useCartStateContext();

  useEffect(async () => {
    const availability = await getCategoryVariantStatus(client, productPageVariants)
    setAvailableProducts(availability)
  }, [productPageVariants]);

  useEffect(() => {
    setQueriedProducts(availableProducts)
  },
  [availableProducts])

  function filterProductVariants (payload) {
    setQueriedProducts([...payload])
  }

  function sortProductVariants (payload) {
    setSortedProducts([...payload])
  }

  // Sticky Menu Area
  useEffect(() => {
    window.addEventListener('scroll', isSticky);
    return () => {
        window.removeEventListener('scroll', isSticky);
    };
  });


  const isSticky = () => {
    const wrapper = document.querySelector('.sticky-filters-wrapper');
    const element = document.querySelector('.sticky-filters');
    const header = document.querySelector('#sticky-header');
    const filtersContainer = document.querySelector('.filters-container');

    if (wrapper?.getBoundingClientRect().top <= header?.getBoundingClientRect().height) {
      element.classList.add('is-sticky')
      filtersContainer.classList.add('relative')
      element.style.top = `${header?.getBoundingClientRect().height}px`
    } else {
      element.classList.remove('is-sticky')
      filtersContainer.classList.remove('relative')
    }
  };


  return (
    <div className="relative sticky-filters-wrapper">
      <div className="mb-8 sticky-filters px-6">
        <div className="py-2 container filters-container mx-auto flex content-center place-content-between">
          <ProductFilter productPageVariants={availableProducts} onApply={(payload) => filterProductVariants(payload)}/>
          <ProductSorting productPageVariants={queriedProducts} onApply={(payload) => sortProductVariants(payload)}/>
        </div>
      </div>
      <div className={clsx({'hidden' : sortedProducts.length})}>
        <div className="flex justify-center text-center h-96 items-center">
        <div className="">
          <div><FormattedMessage id="productView.productsNotFound" /></div>
        </div>
        </div>
      </div>
      <ProductGrid productPageVariants={sortedProducts} />
    </div>
  );
};

export default ModifiedProductGrid;
